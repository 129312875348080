import {
  FormInputGeneric,
  FormLabel,
  FormTextInput,
  FullScreenLoadingSpinner,
  RadioButtons,
  SingleSelectDropdown,
  SubTitle,
} from 'ck-components';
import { ThemeFormData, useEventsIdName } from 'ck-queries';

import { UseFormReturn } from 'react-hook-form';
import { dateFormatInputCanAccept } from 'ck-utils';
import dayjs from 'dayjs';
import { useMemo } from 'react';

interface Props {
  form: UseFormReturn<ThemeFormData>;
}

export const CountdownSection = ({ form }: Props) => {
  const { formState, watch, unregister } = form;
  const { errors } = formState;

  const Q_EventsIdName = useEventsIdName();
  const eventsIdName = useMemo(
    () => Q_EventsIdName.data ?? [],
    [Q_EventsIdName.data]
  );

  const countdownEnabled = watch('countdownEnabled');
  const countdownButtonType = watch('countdownButtonType');
  const start = watch('start');

  if (Q_EventsIdName.isLoading || !Q_EventsIdName.data) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <div className=''>
      <SubTitle label='3. Countdown' />
      <div className='mb-6'>
        <RadioButtons
          parentForm={form}
          name='countdownEnabled'
          onChange={(e) => {
            // Unregister from all countdown fields if countdown is disabled
            if (e.target.value === 'disabled') {
              unregister('countdownStartTime');
              unregister('countdownLinkUrl');
            }
          }}
          selectOptions={[
            { label: 'Ingen countdown', value: 'disabled' },
            { label: 'Med countdown', value: 'enabled' },
          ]}
        />
      </div>
      {countdownEnabled === 'enabled' && (
        <>
          <div className='flex items-center gap-4'>
            <FormTextInput
              label={'START'}
              description='Då countdown börjar visas i appen'
              parentForm={form}
              name='countdownStartTime'
              type='datetime-local'
              minDate={dayjs().format(dateFormatInputCanAccept)}
              maxDate={dayjs(start).format(dateFormatInputCanAccept)}
              options={{
                validate: {
                  isAfterNow: (value) =>
                    dayjs(value).isAfter(dayjs()) ||
                    'Start för countdown måste vara i framtiden',
                  isBeforeStartOfTheme: (value) =>
                    dayjs(value).isBefore(dayjs(start)) ||
                    'Start måste vara innan temats start',
                },
              }}
            />
            <div className='-mb-6 text-2xl font-thin'>-</div>
            <FormLabel label={'SLUT'} description={'Bestäms av temats start'}>
              <FormInputGeneric
                errors={errors}
                value={watch('start')}
                name='endOfCountdown'
                type='datetime-local'
                disabled
              />
            </FormLabel>
          </div>
          <FormLabel label='KNAPP "Read more" på COUNTDOWN' />
          <div className='mt-2 mb-6 w-fit'>
            <RadioButtons
              name='countdownButtonType'
              parentForm={form}
              selectOptions={[
                { label: 'Ingen knapp', value: 'none' },
                { label: 'Öppna event i appen', value: 'in-app' },
                { label: 'Öppna URL i webbläsare', value: 'url' },
              ]}
              onChange={(e) => {
                if (e.target.value === 'none') {
                  unregister('countdownLinkUrl');
                }
              }}
            />
          </div>
          {countdownButtonType === 'in-app' && (
            <SingleSelectDropdown
              label='VÄLJ EVENT'
              form={form}
              withSearch
              description='Välj event'
              emptyStateLabel='Välj event'
              name='countdownButtonEventId'
              values={eventsIdName.map(({ id }) => id)}
              labelFromValue={(id) =>
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                eventsIdName.find((d) => d.id === id)!.name
              }
            />
          )}
          {countdownButtonType === 'url' && (
            <FormLabel label={'LÄNK'}>
              <FormTextInput
                parentForm={form}
                name='countdownLinkUrl'
                options={{
                  required: 'Detta fält får inte lämnas tomt',
                }}
              />
            </FormLabel>
          )}
        </>
      )}
    </div>
  );
};
