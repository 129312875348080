import { useAuth } from 'ck-queries';
import { useMemo } from 'react';

export const useIsSuperAdmin = () => {
  const { user } = useAuth();

  const isSuperAdmin = useMemo(
    () => Boolean(user?.roles.includes('SuperAdmin')),
    [user]
  );
  return isSuperAdmin;
};
