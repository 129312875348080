import { QK } from 'ck-queries';
import { ThemeDto } from './types';
import { fetchWithErrorThrowing } from 'ck-utils';
import { formatThemeData } from './localUtils';
import { useQuery } from '@tanstack/react-query';

// Query hook
export const useThemeActive = () =>
  useQuery({
    queryKey: QK.themes.active,
    queryFn: getThemesActive,
  });

// Query function
export async function getThemesActive(): Promise<ThemeDto[]> {
  const res = await fetchWithErrorThrowing('/themes/active', {
    method: 'GET',
  });

  const data: { themes: ThemeDto[] } = await res.json();

  return formatThemeData(data.themes);
}
