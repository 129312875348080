import { QK } from 'ck-queries';
import { fetchWithErrorThrowing } from 'ck-utils';
import { useQuery } from '@tanstack/react-query';

interface EventsDivisionsResponse {
  divisions: string[];
}

// Query hook
export const useUserSpecificEventsDivisions = () => {
  return useQuery({
    queryKey: QK.events.userSpecificDivisions,
    queryFn: getUserSpecificEventsDivisions,
  });
};

// Query function
const getUserSpecificEventsDivisions = (): Promise<string[]> =>
  fetchWithErrorThrowing('/events/user-specific-divisions', {
    method: 'GET',
  })
    .then((res) => res.json())
    .then(({ divisions }: EventsDivisionsResponse) => divisions);
