import {
  EventReservationType,
  PostCreateEventV1,
  useEventsHosts,
  useUser,
} from 'ck-queries';
import {
  FormTextInput,
  FullScreenLoadingSpinner,
  RadioButtons,
  TicketForm,
} from 'ck-components';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useEffect, useMemo } from 'react';

import dayjs from 'dayjs';

interface Props {
  form: UseFormReturn<PostCreateEventV1>;
  isEdit?: boolean;
}

/**
 * Sub-section of the Create Event form for event type information
 *
 * @param form React Hook Form object for the Create Event form
 * @returns Section of the Create Event form for event type information
 */

export function EventTypeSection({ form, isEdit }: Props) {
  const { trigger, setValue, clearErrors } = form;
  const realeaseDate = useWatch({
    control: form.control,
    name: 'eventReservationDetails.ticketReleaseTime',
  });
  const releaseEndDate = useWatch({
    control: form.control,
    name: 'eventReservationDetails.ticketReservationExpiryTime',
  });
  const startDate = useWatch({ control: form.control, name: 'date' });
  const host = useWatch({ control: form.control, name: 'host' });
  const reservationType = useWatch({
    control: form.control,
    name: 'eventReservationDetails.reservationType',
  });
  const Q_hosts = useEventsHosts();

  const selectedHostData = useMemo(
    () => Q_hosts.data?.find((committee) => committee.name === host),
    [Q_hosts.data, host]
  );

  const allowPaidEvents = useMemo(
    () =>
      selectedHostData?.functionTypes.some(
        (f) => f.functionType === 'PaidEvents'
      ),
    [selectedHostData]
  );

  // if host changed to cannot have paid events, ensuer reservationtype is not paid events
  useEffect(() => {
    if (
      (reservationType === 'Paid' && !allowPaidEvents) ||
      !selectedHostData?.orgNumber
    ) {
      setValue('eventReservationDetails.reservationType', 'Open');
      setValue('eventReservationDetails.accountNumber', undefined);
      setValue('eventReservationDetails.resultUnit', undefined);
    } else if (reservationType === 'Paid' && allowPaidEvents) {
      setValue(
        'eventReservationDetails.accountNumber',
        selectedHostData?.accountNumber ?? ''
      );
      setValue(
        'eventReservationDetails.resultUnit',
        selectedHostData?.resultUnit ?? ''
      );
    } else {
      clearErrors('eventReservationDetails.reservationType');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationType, selectedHostData]);

  const Q_user = useUser();

  // only show paid events as an option of host can create paid events
  const eventReservationDetailsTypeOptions: Array<{
    label: string;
    value: EventReservationType;
  }> = useMemo(
    () =>
      selectedHostData?.functionTypes.find(
        (f) => f.functionType === 'PaidEvents'
      ) && Boolean(selectedHostData?.orgNumber)
        ? [
            { label: 'Öppet', value: 'Open' },
            { label: 'Bokningsbart (Gratis)', value: 'Free' },
            { label: 'Bokningsbart (Köp)', value: 'Paid' },
          ]
        : [
            { label: 'Öppet', value: 'Open' },
            { label: 'Bokningsbart (Gratis)', value: 'Free' },
          ],
    [selectedHostData]
  );

  const gatherFoodPreferencesOptions: Array<{
    label: string;
    value: boolean;
  }> = [
    { label: 'Ja', value: true },
    { label: 'Nej', value: false },
  ];

  if (Q_user.isLoading || Q_hosts.isLoading) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <>
      <div>
        <RadioButtons
          description={
            allowPaidEvents && !selectedHostData?.orgNumber
              ? 'Organisatören måste ha ett organisationsnummer för att kunna skapa betalda events'
              : undefined
          }
          disabled={isEdit}
          label='Typ av event / Type of event'
          name='eventReservationDetails.reservationType'
          parentForm={form}
          selectOptions={eventReservationDetailsTypeOptions}
        />

        {/* FIRST SECTION */}
        {reservationType !== 'Open' && (
          <div className='w-1/2 flex-col gap-6'>
            <div className='flex gap-6'>
              <div className='flex-1'>
                <FormTextInput
                  label={'Första anmälningsdag'}
                  disabled={isEdit && dayjs(dayjs()).isAfter(realeaseDate)}
                  parentForm={form}
                  name='eventReservationDetails.ticketReleaseTime'
                  minDate={dayjs().format('YYYY-MM-DD')}
                  type='datetime-local'
                  options={{
                    onBlur: () =>
                      trigger(
                        'eventReservationDetails.ticketReservationExpiryTime'
                      ),
                    ...(isEdit
                      ? {}
                      : {
                          validate: {
                            isInFuture: () =>
                              (dayjs(realeaseDate).isAfter(dayjs()) &&
                                dayjs(realeaseDate).isBefore(startDate)) ||
                              'Måste vara i framtiden och innan eventet startar.',
                          },
                        }),
                  }}
                />
              </div>
              <div className='flex-1'>
                <FormTextInput
                  label={'Sista anmälningsdag'}
                  parentForm={form}
                  name='eventReservationDetails.ticketReservationExpiryTime'
                  minDate={dayjs().format('YYYY-MM-DD')}
                  type='datetime-local'
                  options={{
                    onBlur: () => trigger(),
                    validate: {
                      isInFuture: () =>
                        dayjs(releaseEndDate).isAfter(dayjs(realeaseDate)) ||
                        'Måste vara efter biljettsläpp.',
                    },
                  }}
                />
              </div>
            </div>

            {reservationType === 'Paid' && (
              <div className='flex gap-6'>
                <div className='flex-1'>
                  <FormTextInput
                    label='Bokföringskonto'
                    name='eventReservationDetails.accountNumber'
                    disabled={isEdit}
                    parentForm={form}
                    placeholder='Ange bokföringskonto'
                    maxLength={4}
                    minLength={4}
                  />
                </div>
                <div className='flex-1'>
                  <FormTextInput
                    label='Resultatenhet'
                    name='eventReservationDetails.resultUnit'
                    disabled={isEdit}
                    parentForm={form}
                    placeholder='Ange resultatenhet'
                    maxLength={2}
                    minLength={2}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        {/* SECOND SECTION */}
        {reservationType !== 'Open' && (
          <>
            <TicketForm disablePrice={reservationType !== 'Paid'} form={form} />
            <FormTextInput
              label='Antal platser'
              name='eventReservationDetails.numberOfTickets'
              parentForm={form}
              options={{
                pattern: { value: /^\d+$/, message: 'Endast siffror' },
              }}
              placeholder='Antal platser'
              className='w-1/2'
            />

            <RadioButtons
              label='Fråga efter matpreferenser'
              name='eventReservationDetails.gatherFoodPreferences'
              parentForm={form}
              selectOptions={gatherFoodPreferencesOptions}
            />
          </>
        )}
      </div>
    </>
  );
}
