import { QK } from 'ck-queries';
import { formatThemePrefill } from 'ck-utils';
import { useQuery } from '@tanstack/react-query';
import { useThemeActive } from './useThemeActive';
import { useThemeDraft } from './useThemeDraft';
import { useThemeHistory } from './useThemeHistory';
import { useThemeUpcoming } from './useThemeUpcoming';

export const useThemePrefill = (
  themeId: string | undefined,
  isCopy: boolean
) => {
  const { data: activeThemes } = useThemeActive();
  const { data: futureThemes } = useThemeUpcoming();
  const { data: historyThemes } = useThemeHistory();
  const { data: draftThemes } = useThemeDraft();

  const themes = [
    ...(activeThemes ?? []),
    ...(historyThemes ?? []),
    ...(futureThemes ?? []),
    ...(draftThemes ?? []),
  ];

  return useQuery({
    queryKey: [...QK.themes.prefill, themeId, isCopy],
    queryFn: async () => {
      const maybeTheme = themes.find(({ id }) => id === themeId);
      return formatThemePrefill(maybeTheme, isCopy, themeId);
    },
    enabled: Boolean(activeThemes),
  });
};
