import { PostAlarm } from 'ck-queries';

export const defaultAlarmValues: Omit<PostAlarm, 'targetGroup'> = {
  inboxMessageEnglish: '',
  inboxMessageSwedish: '',
  titleEnglish: '',
  titleSwedish: '',
  notificationMessageEnglish: '',
  notificationMessageSwedish: '',
};
