import { EyeOff, EyeOn } from 'ck-assets';
import {
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
  UseFormReturn,
} from 'react-hook-form';
import React, { useState } from 'react';
import {
  getMaxLengthOption,
  getMinLengthOption,
  getRequiredOption,
} from './ErrorMessagesNew/errorOptions';

import { ErrorMessages } from 'ck-components';
import { FormLabelWithCount } from './FormLabel/FormLabelWithCount';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';

export interface FormInputProps<T extends FieldValues>
  extends React.HTMLProps<HTMLInputElement> {
  name: Path<T>;
  description?: string;
  optional?: boolean;
  parentForm: UseFormReturn<T>;
  options?: RegisterOptions;
  errorsAlwaysTakeUpSpace?: boolean;
  onChangeEffect?: (a: string) => void;
  className?: string;
  isPassword?: boolean;
  minDate?: string;
  maxDate?: string;
}

/**
 * A custom wrapper for the HTML input component. Extends the functionality with some basic error handling as
 * provided by react-hook-form.
 *
 * @template T The type of the field values object
 *
 * @param props.name The name of the field to be registered
 * @param props.label The label for the input element (optional)
 * @param props.description The description for the input element (optional)
 * @param props.optional Indicates whether the input field is optional. Defaults to required.
 * @param props.parentForm The `useForm` hook from react-hook-form with all needed functions
 * @param props.options An object containing options you can pass in to the `register` function from react-hook-form (optional)
 * @param props.errorsAlwaysTakeUpSpace Indicates whether the input errors should always take up space (optional)
 * @param props.onChangeEffect side-effect run when value is manually changed (optional)
 */

export const FormTextInput = <T extends FieldValues>({
  name,
  options = {},
  disabled = false,
  readOnly = false,
  type = 'text',
  errorsAlwaysTakeUpSpace = true,
  parentForm,
  label,
  optional,
  maxLength,
  minLength,
  description,
  className,
  isPassword,
  minDate,
  maxDate,
  onChangeEffect,
  ...rest
}: FormInputProps<T>) => {
  const { formState, register, setValue } = parentForm;
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={clsx('relative w-full', className)}>
      {label && (
        <FormLabelWithCount
          name={name}
          label={label}
          description={description}
          parentForm={parentForm}
          maxLength={maxLength}
        />
      )}

      <input
        {...register(name, {
          required: getRequiredOption(optional),
          maxLength: getMaxLengthOption(maxLength, label),
          minLength: getMinLengthOption(minLength, label),
          ...options,
        })}
        id={name}
        min={minDate}
        max={maxDate}
        {...rest}
        onChange={(e) => {
          rest.onChange?.(e) ??
            setValue(name, e.target.value as PathValue<T, Path<T>>);
          onChangeEffect?.(e.target.value);
        }}
        maxLength={maxLength}
        disabled={disabled}
        type={isPassword ? (showPassword ? 'text' : 'password') : type}
        className={clsx(
          'h-14 w-full rounded border border-gray-300 px-4 focus:outline-sky-500',
          disabled &&
            'cursor-not-allowed bg-neutral-50  text-gray-400 placeholder:text-gray-400 hover:border-gray-300 focus:border-gray-300',
          readOnly &&
            'border-none bg-inherit caret-transparent hover:cursor-default focus:border-none focus:outline-none'
        )}
      />

      {isPassword && (
        <div
          onClick={() => setShowPassword(!showPassword)}
          className='absolute top-12 right-4 h-6 w-6 hover:cursor-pointer'
        >
          {showPassword ? <ReactSVG src={EyeOn} /> : <ReactSVG src={EyeOff} />}
        </div>
      )}

      <ErrorMessages
        errors={formState.errors}
        name={name}
        takeUpSpace={errorsAlwaysTakeUpSpace}
      />
    </div>
  );
};
