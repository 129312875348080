import { ChevronDown, ChevronUp } from 'ck-assets';

import { Listbox } from '@headlessui/react';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';

interface SingleSelectDropdownButtonProps {
  disabled: boolean | undefined;
  open: boolean;
  emptyStateLabel: string;
  displayLabel: string | undefined;
  name: string;
}

/**
 * Sub-component of DropdownSelector.tsx
 * Separated to make it readable.
 *
 * @returns DropdownButton for use in DropdownSelector
 */
export const SingleSelectDropdownButton = ({
  name,
  disabled,
  displayLabel,
  open,
  emptyStateLabel,
}: SingleSelectDropdownButtonProps) => (
  <Listbox.Button
    id={name}
    className={clsx(
      'mb-0 flex h-14 w-full items-center justify-between rounded border border-gray-300 bg-white px-4 text-left focus:outline-sky-500',
      disabled &&
        'cursor-not-allowed bg-neutral-50 text-gray-400 placeholder:text-gray-400 hover:border-gray-300 focus:border-gray-300'
    )}
  >
    {displayLabel ? (
      displayLabel
    ) : (
      <span className='text-gray-400'>{emptyStateLabel}</span>
    )}
    <ReactSVG src={open ? ChevronUp : ChevronDown} className='w-6' />
  </Listbox.Button>
);
