export const downloadResponse = async (response: Response, filename?: string) =>
  response.blob().then((blob) => {
    // Create blob URL without explicitly setting the download attribute
    const url = window.URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    if (filename) {
      link.setAttribute('download', filename); // Set the filename
    }

    link.style.display = 'none'; // Hide the link
    document.body.appendChild(link);

    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);
  });
