import {
  EventObj,
  EventReservationType,
  targetUserTypeMap,
  useEventsCategories,
} from 'ck-queries';
import {
  InformationModal,
  ModalColumn,
  ModalHorizontalDivider,
  ModalLabelAndText,
} from 'ck-components';

import dayjs from 'dayjs';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  data?: EventObj;
}

export const EventArchiveDetailsModal = ({
  isOpen,
  setIsOpen,
  data,
}: Props) => {
  const Q_categories = useEventsCategories();
  const allCategoryNames = Q_categories?.data
    ?.filter(({ id }) => data?.categoryIds.includes(id))
    .map((c) => c.name);

  if (!data) return null;

  const translateStatus = (status: EventReservationType): string => {
    switch (status) {
      case 'Free':
        return 'Bokningsbart (Gratis)';
      case 'Open':
        return 'Öppet';
      case 'Paid':
        return 'Bokningsbart (Betalt)';
      default:
        return status;
    }
  };

  return (
    <InformationModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      customStyle='md:min-w-[1200px]'
    >
      {data && (
        <div>
          <div className='flex flex-row justify-between gap-2'>
            <ModalColumn title='Svenska'>
              <ModalLabelAndText label='EVENTNAMN' text={data.nameSwedish} />
              <ModalLabelAndText
                label='BESKRIVNING'
                text={data.descriptionSwedish}
              />
              <ModalLabelAndText
                label='PLATS FÖR EVENTET'
                text={data.locationSwedish}
              />
              {data.eventLink && (
                <ModalLabelAndText label='LÄNK' text={data.eventLink} />
              )}
              {data.linkButtonTextSwedish && (
                <ModalLabelAndText
                  label='KNAPPTEXT'
                  text={data.linkButtonTextSwedish}
                />
              )}
            </ModalColumn>
            <ModalColumn title='Engelska'>
              <ModalLabelAndText label='EVENT NAME' text={data.name} />
              <ModalLabelAndText label='DESCRIPTION' text={data.description} />
              <ModalLabelAndText label='EVENT LOCATION' text={data.location} />
              {data.eventLink && (
                <ModalLabelAndText label='LÄNK' text={data.eventLink} />
              )}
              {data.linkButtonText && (
                <ModalLabelAndText
                  label='BUTTON TEXT'
                  text={data.linkButtonText}
                />
              )}
            </ModalColumn>
          </div>
          <ModalHorizontalDivider />
          <div className='justify-between gap-4 md:flex md:flex-row'>
            <ModalColumn>
              <ModalLabelAndText label='ORGANISTÖR' text={data.host} />
              <ModalLabelAndText
                label='MÅLGRUPP'
                text={data.audience.join(', ')}
                className='max-w-[180px]'
              />
            </ModalColumn>
            <ModalColumn>
              <ModalLabelAndText
                label='EVENTET START'
                text={dayjs(data.startTime).format('DD MMM YYYY, HH:mm')}
              />
              <ModalLabelAndText
                label='EVENTET SLUTAR'
                text={dayjs(data.endTime).format('DD MMM YYYY, HH:mm')}
              />
            </ModalColumn>
            <ModalColumn>
              {allCategoryNames && (
                <ModalLabelAndText
                  label={'EVENTKATEGORI'}
                  text={allCategoryNames.join(', ')}
                />
              )}
              <ModalLabelAndText
                label='EVENTETS SPRÅK'
                text={data.heldInLanguage}
              />
            </ModalColumn>
            {data.imageUrl && (
              <ModalColumn>
                {data.imageUrl && (
                  <div className='max-w-2xl'>
                    <h3 className='mb-1 font-light uppercase tracking-custom'>
                      BILD
                    </h3>
                    <img src={data.imageUrl} className='max-w-[170px]' />
                  </div>
                )}
                {data.imageCred && (
                  <ModalLabelAndText label='PHOTO CRED' text={data.imageCred} />
                )}
              </ModalColumn>
            )}
          </div>
          <ModalHorizontalDivider />
          <div className='justify-between gap-4 md:flex md:flex-row'>
            <ModalColumn>
              {data.eventReservationDetails.reservationType && (
                <ModalLabelAndText
                  label='TYP AV EVENT'
                  text={translateStatus(
                    data.eventReservationDetails.reservationType
                  )}
                />
              )}

              {data.eventReservationDetails.numberOfTickets && (
                <ModalLabelAndText
                  label='ANTAL PLATSER'
                  text={data.eventReservationDetails.numberOfTickets.toString()}
                  className='max-w-[180px]'
                />
              )}

              {data.eventReservationDetails.accountNumber && (
                <ModalLabelAndText
                  label='BOKFÖRINGSKONTO'
                  text={data.eventReservationDetails.accountNumber}
                />
              )}
            </ModalColumn>
            <ModalColumn>
              {data.eventReservationDetails.ticketReservationExpiryTimeUtc && (
                <ModalLabelAndText
                  label='FÖRSTA ANMÄLNINGSDAG'
                  text={dayjs(
                    data.eventReservationDetails.ticketReleaseTimeUtc
                  ).format('DD MMM YYYY, HH:mm')}
                />
              )}
              {data.eventReservationDetails.ticketReservationExpiryTimeUtc && (
                <ModalLabelAndText
                  label='SISTA ANMÄLNINGSDAG'
                  text={dayjs(
                    data.eventReservationDetails.ticketReservationExpiryTimeUtc
                  ).format('DD MMM YYYY, HH:mm')}
                />
              )}

              {data.eventReservationDetails.resultUnit && (
                <ModalLabelAndText
                  label='RESULTATENHET'
                  text={data.eventReservationDetails.resultUnit}
                />
              )}
            </ModalColumn>
            <ModalColumn>
              {data.eventReservationDetails.reservationType !== 'Open' && (
                <ModalLabelAndText
                  label='FRÅGA EFTER MATPREFERENSER'
                  text={
                    data.eventReservationDetails.gatherFoodPreferences
                      ? 'Ja'
                      : 'Nej'
                  }
                />
              )}
            </ModalColumn>
            {data.eventReservationDetails.reservationType !== 'Open' && (
              <ModalColumn>
                {data.eventReservationDetails.tickets?.length && (
                  <div className={'max-w-2xl'}>
                    <h3 className='mb-1 font-light uppercase tracking-custom'>
                      BILJETTER
                    </h3>
                    {data.eventReservationDetails.tickets.map((ticket, key) => (
                      <div key={key} className='flex justify-between'>
                        <p>{ticket.swedishName}</p>
                        <p>{ticket.price} kr</p>
                      </div>
                    ))}
                  </div>
                )}
              </ModalColumn>
            )}
          </div>

          <ModalHorizontalDivider />
          <div className='mt-4 flex items-center justify-between gap-2'>
            <div className='mt-4 flex items-center gap-8'>
              <ModalLabelAndText
                label='VISAS FÖR'
                text={targetUserTypeMap[data.targetUserType]}
              />
              <ModalLabelAndText label='SKAPAD AV' text={data.createdBy} />
            </div>
            <p className='font-thin italic'>Senast ändrad {data.updatedAt}</p>
          </div>
        </div>
      )}
    </InformationModal>
  );
};
