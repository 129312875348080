import { FormTextInput, SingleSelectDropdown } from 'ck-components';
import {
  PostHostReportForm,
  PostPayeeReportForm,
  ReportRangeArray,
  ReportRangeType,
} from 'ck-queries';

import { UseFormReturn } from 'react-hook-form';
import { usePresetReportRanges } from '../hooks/usePresetReportRanges';

export interface ReportRangeProps {
  form: UseFormReturn<
    PostHostReportForm | (PostPayeeReportForm & { period: ReportRangeType })
  >;
}

export const ReportRange = ({ form }: ReportRangeProps) => {
  const ranges = usePresetReportRanges();
  const { setValue } = form;

  return (
    <div className='flex w-full flex-row content-start gap-4'>
      <div className='w-80'>
        <SingleSelectDropdown
          name='period'
          form={form}
          label='PERIOD'
          onChangeEffect={(value: ReportRangeType) => {
            if (value !== 'Anpassad') {
              setValue(
                'from',
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ranges.find((r) => r.label === value)!.range.start
              );
              setValue(
                'to',
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ranges.find((r) => r.label === value)!.range.end
              );
            }
          }}
          values={ReportRangeArray}
          hideValues={['Anpassad']}
          labelFromValue={(value) => value}
        />
      </div>
      <div className='w-44'>
        <FormTextInput
          type='date'
          parentForm={form}
          name={'from'}
          onChangeEffect={() => {
            setValue('period', 'Anpassad');
          }}
          label='FRÅN'
        />
      </div>

      <div className='w-44'>
        <FormTextInput
          type='date'
          parentForm={form}
          name={'to'}
          onChangeEffect={() => {
            setValue('period', 'Anpassad');
          }}
          label='till'
        />
      </div>
    </div>
  );
};
