import { FieldValues, Path, PathValue, UseFormReturn } from 'react-hook-form';
import { useEffect, useState } from 'react';

import { ListOption } from './ListOption';
import { Listbox } from '@headlessui/react';
import { ReactSVG } from 'react-svg';
import Search from '../../../assets/icons/Search.svg';
import clsx from 'clsx';

interface Props<T extends FieldValues> {
  parentForm: UseFormReturn<T>;
  name: Path<T>;
  formLabel: string;
  description?: string;
  optionsData: PathValue<T, Path<T>>[];
  labelFromOption: (option: PathValue<T, Path<T>>) => string;
}

export const ListOptionsWithSearch = <T extends FieldValues>({
  parentForm,
  name,
  labelFromOption,
  optionsData,
}: Props<T>) => {
  const { watch } = parentForm;
  const selectedOptions = watch(name);
  const [currentSearch, setCurrentSearch] = useState<string>('');
  const [filteredSearch, setFilteredSearch] =
    useState<PathValue<T, Path<T>>[]>(optionsData);

  useEffect(() => {
    setFilteredSearch(
      currentSearch
        ? optionsData.filter(
            (option) =>
              labelFromOption(option).toLowerCase().includes(currentSearch) ||
              currentSearch === ''
          )
        : optionsData
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearch, optionsData]);

  return (
    <Listbox.Options
      className={clsx(
        'absolute z-40 mt-1 flex w-full flex-col overflow-y-auto rounded border-2 border-gray-300 bg-white'
      )}
    >
      <div className='relative max-h-[300px]'>
        <div className='m-3 flex flex-row justify-start rounded-3xl border border-gray-200 p-1  '>
          <ReactSVG src={Search} />
          <input
            type='text'
            placeholder='Sök'
            value={currentSearch}
            className='text-slate-300 focus:outline-none'
            onChange={(e) =>
              setCurrentSearch(e.currentTarget.value.toLowerCase())
            }
          />
        </div>
        {filteredSearch.map((option) => (
          <ListOption
            labelFromOption={labelFromOption}
            option={option}
            currentSelected={selectedOptions}
            key={option}
          />
        ))}
      </div>
    </Listbox.Options>
  );
};
