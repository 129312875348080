import React from 'react';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';

interface Props {
  onClick?: (eventId: string) => void;
  label: string;
  icon: string;
  iconStyle?: string;
}

const HoverLink = ({ onClick, label, icon, iconStyle }: Props) => {
  return (
    <div
      onClick={() => onClick}
      className='group flex items-center gap-2 group-hover:cursor-pointer'
    >
      <h3 className='font-bold opacity-0 transition-opacity duration-100 group-hover:cursor-pointer group-hover:text-sky-500 group-hover:opacity-100'>
        {label}
      </h3>

      <ReactSVG
        src={icon}
        className={clsx(
          iconStyle,
          'duration-100 group-hover:cursor-pointer group-hover:text-sky-500'
        )}
      />
    </div>
  );
};

export default HoverLink;
