import { ArrElement, Table, TableProps } from 'ck-components';
import { ArrowLeft, ArrowRight } from 'ck-assets';
import usePaginationState, { PaginationState } from './usePaginationReducer';

import PaginationCollapseButton from './PaginationCollapseButton';
import PaginationNumberButton from './PaginationNumberButton';
import { createContext } from 'react';

interface PaginationTableProps<T> extends TableProps<T> {
  elementsPerPage: number;
  nrOfPaginationNumbers?: number;
}

interface PaginationContextProps<T> {
  state: PaginationState<T>;
  changePage: (index: number) => void;
}

export const PaginationContext = (<T,>() =>
  createContext<PaginationContextProps<T>>({
    state: {
      currentPage: 0,
      dataToDisplay: [] as T,
      formattedData: [],
      numberOfPages: 0,
    },
    changePage: () => undefined,
  }))();

/**
 * This is a wrapper for the table component which adds pagination below the table component.
 *
 * Issue: when clicking the 'next'-button the highlighted button index isn't sliding as I want it. Minor
 * UI thing though.
 *
 * @param elementsPerPage denotes how many elements will be displayed on each page
 * @param nrOfPaginationNumbers denotes how many buttons with numbers are shown before
 * the collapse button (the three dots) is being rendered.
 */

export function TableWithPagination<
  T extends Array<{ [key in keyof ArrElement<T>]: unknown }>
>({
  elementsPerPage,
  data,
  nrOfPaginationNumbers = 5,
  ...rest
}: PaginationTableProps<T>) {
  const { state, changePage } = usePaginationState({ elementsPerPage, data });

  const renderSetAmountOfPageButtons = (startPage = 0) => {
    const buttons = [];
    for (let index = 0; index < nrOfPaginationNumbers; index++) {
      buttons.push(
        <PaginationNumberButton index={index + startPage} key={index} />
      );
    }

    return buttons;
  };

  /**
   * When documenting this method, 'nrOfPaginationNumbers' and X are used
   * interchangeably for simplification purposes.
   *
   * There are four possible outcomes when rendering the pagination buttons:
   *
   * 1. We have less pages to display than specified with nrOfPaginationNumbers.
   * This means we just render all page numbers as is, without adding any dots/collapse
   * signs at the end or beginning.
   * 2. There are more pages to render than specified with nrOfPaginationNumbers, and we are
   * currently located within one of the first X pages. This means we need to add three dots at the end of
   * X pages, and also render the last index in order to enable a "quick jump".
   * 3. We are currently located in the middle of the page numbers. This means we need to render
   * three dots on both ends of the pagination span (nrOfPaginationNumbers) and also append the first
   * and last index respectively to enable quick jump.
   * 4. We are rendering the last X elements of the pagination buttons. This means we need to add three
   * dots _before_ the enumeration.
   */

  const renderPageButtons = () => {
    // we have less than X pages to render
    if (state.numberOfPages <= nrOfPaginationNumbers) {
      return state.formattedData.map((data, index) => (
        <PaginationNumberButton key={`${data.key}-${index}`} index={index} />
      ));
    }

    // we have more than X pages to render in total, but we are still
    // viewing one of the first X pages
    if (state.currentPage - nrOfPaginationNumbers < 0) {
      return (
        <>
          {renderSetAmountOfPageButtons(0)}
          <PaginationCollapseButton
            nrOfPaginationNumbers={nrOfPaginationNumbers}
          />
          <PaginationNumberButton index={state.numberOfPages - 1} />
        </>
      );
    }

    // we're in the middle of all pages
    // need to render dots on both sides
    if (
      0 <= state.currentPage - nrOfPaginationNumbers &&
      state.currentPage + nrOfPaginationNumbers < state.numberOfPages
    ) {
      return (
        <>
          <PaginationNumberButton index={0} />
          <PaginationCollapseButton
            nrOfPaginationNumbers={nrOfPaginationNumbers}
            jumpBackwards
          />
          {renderSetAmountOfPageButtons(state.currentPage)}
          <PaginationCollapseButton
            nrOfPaginationNumbers={nrOfPaginationNumbers}
          />
          <PaginationNumberButton index={state.numberOfPages - 1} />
        </>
      );
    }

    // we are viewing one of the last X pages
    if (state.numberOfPages <= state.currentPage + nrOfPaginationNumbers) {
      return (
        <>
          <PaginationNumberButton index={0} />
          <PaginationCollapseButton
            nrOfPaginationNumbers={nrOfPaginationNumbers}
            jumpBackwards
          />
          {renderSetAmountOfPageButtons(
            state.numberOfPages - nrOfPaginationNumbers
          )}
        </>
      );
    }
  };

  return (
    <>
      <div className='space-between flex flex-1 flex-col'>
        <Table {...rest} data={state.dataToDisplay} />
        <PaginationContext.Provider value={{ state, changePage }}>
          {data.length > elementsPerPage ? (
            <div className='mt-10 flex w-full flex-1 items-center justify-center gap-4'>
              <div
                className='flex h-6 w-6 border-spacing-0 items-center justify-center rounded-full bg-neutral-200 hover:cursor-pointer hover:bg-sky-500'
                onClick={() => {
                  state.currentPage !== 0
                    ? changePage(state.currentPage - 1)
                    : () => undefined;
                }}
              >
                <div className='w-3/5'>
                  <img src={ArrowLeft} alt='Left arrow' />
                </div>
              </div>
              {renderPageButtons()}
              <div
                className='flex h-6 w-6 border-spacing-0 items-center justify-center rounded-full bg-neutral-200 hover:cursor-pointer hover:bg-sky-500'
                onClick={() => {
                  state.currentPage !== state.numberOfPages - 1
                    ? changePage(state.currentPage + 1)
                    : () => undefined;
                }}
              >
                <div className='w-3/5'>
                  <img src={ArrowRight} alt='arrowRight' />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </PaginationContext.Provider>
      </div>
    </>
  );
}
