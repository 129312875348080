import { DivisionFormatted, QK, getDivisions, useDivisions } from 'ck-queries';
import { LoadingSpinner, MainTitle } from 'ck-components';
import { TypedNavigate, useIsSuperAdmin } from 'ck-utils';

import { ArchiveTab } from 'ck-features';
import { organisationsTableStructure } from './types';
import { useNavigate } from 'react-router-dom';

/**
 *
 * @returns list all organisations (divisions)
 * Only superadmin can reach this page (set in <Navbar/>)
 */
export function Organisations() {
  const navigate = useNavigate();
  const Q_divisions = useDivisions();
  const isSuperAdmin = useIsSuperAdmin();
  if (!isSuperAdmin) {
    <TypedNavigate to='/' replace={true} />;
  }
  return (
    <div className='flex w-full gap-10'>
      <div className='flex w-full flex-col gap-6'>
        {/* Following div avoids layout shift for the MainTitle between Organistion -> Division */}
        <div className='h-6' />
        <MainTitle label='Organisationer' />
        {Q_divisions.isLoading && (
          <div className='mt-20 flex w-full items-center justify-center'>
            <LoadingSpinner width={100} />
          </div>
        )}
        {Q_divisions.isError && (
          <div>
            Något gick fel vid inläsning av organisationer. {<br />}
            Prova uppdatera webbläsaren.
          </div>
        )}
        {Q_divisions.isSuccess && (
          <ArchiveTab
            tableStructure={organisationsTableStructure}
            emptyStateMessage={'Det finns inga organisationer'}
            onRowClick={(data: DivisionFormatted) =>
              navigate(`/organisations/${data.id}`)
            }
            queryConfig={{
              queryKey: QK.divisions,
              queryFn: getDivisions,
            }}
          />
        )}
      </div>
    </div>
  );
}
