import { PropsWithChildren } from 'react';

export const Label = ({
  text,
  children,
}: { text?: string } & PropsWithChildren) => {
  return (
    <div className='whitespace-nowrap text-left text-base font-light uppercase tracking-custom text-gray-800'>
      {text ?? children}
    </div>
  );
};
