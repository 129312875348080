import { useDivisions } from './useDivisions';
import { useMemo } from 'react';

export const useDivision = (divisionId: string) => {
  const Q_DIVISIONS = useDivisions();
  return useMemo(() => {
    if (Q_DIVISIONS.data) {
      return {
        division: Q_DIVISIONS.data.find((d) => d.id === divisionId),
        isLoading: Q_DIVISIONS.isLoading,
      };
    }
    return {
      division: undefined,
      isLoading: Q_DIVISIONS.isLoading,
    };
  }, [Q_DIVISIONS.data, divisionId, Q_DIVISIONS.isLoading]);
};
