import { Poll, PollPrefill, PostPollsRequest } from './types';
import {
  dateFormatInputCanAccept,
  fetchImageFromRemoteBlobStorage,
} from 'ck-utils';

import { QK } from 'ck-queries';
import dayjs from 'dayjs';
import { usePolls } from './usePolls';
import { usePollsDraft } from './usePollsDraft';
import { useQuery } from '@tanstack/react-query';

export const usePollsPrefill = (
  pollId: string | undefined,
  isCopy: boolean
) => {
  // Get the polls
  const { data: scheduledPolls } = usePolls('scheduled');
  const { data: draftPolls } = usePollsDraft();
  const { data: historyPolls } = usePolls('history');

  const polls = [
    ...(scheduledPolls ?? []),
    ...(draftPolls ?? []),
    ...(historyPolls ?? []),
  ];

  return useQuery({
    queryKey: [...QK.polls.prefill, pollId, isCopy],
    queryFn: async () => {
      const maybePoll = polls.find(({ id }) => id === pollId);
      return formatPollPrefill(maybePoll, isCopy, pollId);
    },
    enabled: Boolean(polls),
  });
};

const formatPollPrefill = async (
  poll: Poll | undefined,
  isCopy: boolean,
  pollId: string | undefined
): Promise<{ prefill: PollPrefill }> => {
  if (!(pollId && poll)) {
    return {
      prefill: undefined,
    };
  }

  const titleSwedish = isCopy
    ? `Kopia av ${poll.titleSwedish}`
    : poll.titleSwedish;

  const titleEnglish = isCopy
    ? `Copy of ${poll.titleEnglish}`
    : poll.titleEnglish;

  // Get image from remote blob storage with imageUrl
  const image = await fetchImageFromRemoteBlobStorage(poll.imageUrl);

  // Map from got data to the format the form expects
  const mapping: PostPollsRequest = {
    sendPushNotification: poll.shouldSendPushNotification ? 'true' : 'false',
    targetGroup: poll.targetGroup,
    titleEnglish: titleEnglish,
    titleSwedish: titleSwedish,
    questionEnglish: poll.questionEnglish,
    questionSwedish: poll.questionSwedish,
    scheduleTime: dayjs(poll.scheduleTime).format(dateFormatInputCanAccept),
    answersSwedish: poll.answers.map((a) => a.answerSwedish) ?? [],
    answersEnglish: poll.answers.map((a) => a.answerEnglish) ?? [],
    image,
    isDraft: poll.isDraft,
  };

  // at this point pollid must be a string
  return { prefill: { ...mapping, id: pollId as string } };
};
