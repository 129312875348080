import { QK } from 'ck-queries';
import { User } from './types';
import { fetchWithErrorThrowing } from 'ck-utils';
import { useQuery } from '@tanstack/react-query';

/**
 * Called on an interval to refresh accessToken and refreshToken.
 * Tokens are managed by server through cookies.
 */
export const useRefreshToken = (user: User | null) => {
  // Queries always need return data so this is just a boolean.
  // Usually this is for the tokens but they're managed in cookies.
  return useQuery<boolean>({
    queryFn: postRefreshToken,
    queryKey: [QK.refreshToken],
    refetchInterval: 2 * 60 * 1000, // 2 min
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: true,
    enabled: Boolean(user),
  });
};

async function postRefreshToken(): Promise<boolean> {
  await fetchWithErrorThrowing('/auth/refresh-token', {
    method: 'POST',
  });

  // Dummy value to satisfy useQuery's demand for return data
  return true;
}
