import { ClickableText, MainTitle } from 'ck-components';
import { ForgotPasswordModal, LoginForm } from 'ck-features';
import { chalmersLogo, csuGroupPicture } from 'ck-assets';
import { useEffect, useState } from 'react';

import { useAuth } from 'ck-queries';
import { useNavigate } from 'react-router-dom';

export function LoginPage() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [showPasswordModal, setShowPasswordModal] = useState(false);

  // Redirect if we have a user
  useEffect(() => {
    if (user) {
      navigate('/push-notifications/create');
    }
  }, [user, navigate]);

  return (
    <>
      <ForgotPasswordModal
        isOpen={showPasswordModal}
        setIsOpen={setShowPasswordModal}
      />
      <div className='flex h-screen w-full flex-row justify-between'>
        <div className='flex h-full min-w-[500px] flex-grow flex-col items-center justify-center '>
          <div className='m-10 mt-40 flex w-full max-w-xl flex-col items-center justify-center p-12 '>
            <MainTitle label='Logga in' customStyle=' mb-6' />
            <LoginForm />
            <ClickableText
              onPress={() => setShowPasswordModal(true)}
              label='Glömt lösenord'
              customStyle='mt-10 text-base font-semibold text-center hover:cursor-pointer'
            />
          </div>
          <img
            src={chalmersLogo}
            alt='The Chalmers Student Union Logo'
            className='mt-10 p-5'
          />
        </div>
        <img
          src={csuGroupPicture}
          alt='a placeholder pic'
          className='h-full w-7/12 overflow-hidden object-cover object-top'
        />
      </div>
    </>
  );
}
