import { EditInfo, FullScreenLoadingSpinner, MainTitle } from 'ck-components';

import { CreatePushNotificationForm } from 'ck-features';
import { TypedNavigate } from 'ck-utils';
import { useNotificationPrefill } from 'ck-queries';
import { useParams } from 'react-router-dom';

interface Props {
  isEdit?: boolean;
  isCopy?: boolean;
}
export function PushNotificationsCreatePage({ isCopy, isEdit }: Props) {
  // Id provided by the route when editing a notification
  const { id } = useParams();
  const { data, isLoading } = useNotificationPrefill(id, Boolean(isCopy));

  // Get the values of the notification to edit
  const prefill = data?.prefill;

  if (isLoading) {
    return <FullScreenLoadingSpinner />;
  }

  if (!prefill && id) {
    return <TypedNavigate to='/push-notifications/create' replace={true} />;
  }

  const mainTitle = isEdit
    ? 'Redigerar pushnotis'
    : isCopy
    ? 'Kopiera pushnotis'
    : 'Skapa pushnotis';

  return (
    <div className='flex flex-1 flex-col'>
      {prefill && <EditInfo isDraft={isEdit && prefill.isDraft} />}
      <MainTitle label={mainTitle} customStyle='pb-8' />

      <CreatePushNotificationForm
        isEdit={isEdit}
        prefill={prefill}
        isCopy={isCopy}
      />
    </div>
  );
}
