import { Location, useLocation } from 'react-router-dom';

import { PageName } from 'ck-global-types';

// Define a new type that extends NavigateFunction and overrides the pathname property with PageName
type TypedLocationFunction = Omit<Location, 'pathname'> & {
  pathname: PageName;
};

/**
 * Custom hook that wraps the useLocation hook and adds a type to the pathname property.
 * @returns {{hash: string, key: string, pathname: PageName, search: string, state: unknown}}
 */
export const useTypedLocation = (): TypedLocationFunction => {
  // Call useLocation to get the current location object
  const location = useLocation();

  // Copy all the properties of the location object into a new object, and type the pathname property as PageName
  return { ...location, pathname: location.pathname as PageName };
};
