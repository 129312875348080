import { ReportRangeType } from 'ck-queries';
import dayjs from 'dayjs';

type ReportRange = {
  label: ReportRangeType;
  range: { start: string; end: string };
};

/**
 *
 * @returns values for ranges used for economic reports
 */

export const usePresetReportRanges = () => {
  // This month is default range
  const thisMonth = { start: new Date(), end: new Date() };
  const today = { start: new Date(), end: new Date() };
  const yesterday = { start: new Date(), end: new Date() };
  const previousMonth = { start: new Date(), end: new Date() };

  thisMonth.start.setDate(1);
  thisMonth.end.setDate(dayjs(thisMonth.start).daysInMonth());

  yesterday.start.setDate(yesterday.start.getDate() - 1);
  yesterday.end.setDate(yesterday.end.getDate() - 1);

  previousMonth.start.setMonth(thisMonth.start.getMonth() - 1);
  previousMonth.end.setMonth(thisMonth.end.getMonth() - 1);
  previousMonth.start.setDate(1);
  previousMonth.end.setDate(dayjs(previousMonth.start).daysInMonth());

  return [
    {
      label: 'Denna månad',
      range: {
        start: dayjs(thisMonth.start).format('YYYY-MM-DD'),
        end: dayjs(thisMonth.end).format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Föregående månad',
      range: {
        start: dayjs(previousMonth.start).format('YYYY-MM-DD'),
        end: dayjs(previousMonth.end).format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Idag',
      range: {
        start: dayjs(today.start).format('YYYY-MM-DD'),
        end: dayjs(today.end).format('YYYY-MM-DD'),
      },
    },
    {
      label: 'Igår',
      range: {
        start: dayjs(yesterday.start).format('YYYY-MM-DD'),
        end: dayjs(yesterday.end).format('YYYY-MM-DD'),
      },
    },
  ] satisfies ReportRange[];
};
