import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QK } from 'ck-queries';
import { fetchWithErrorThrowing } from 'ck-utils';
import toast from 'react-hot-toast';

// Mutation hook
export const useDeleteCommittee = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) =>
      toast.promise(
        deleteCommittee(id),
        {
          loading: 'Laddar...',
          success: `Kommittén är raderad`,
          error: 'Kunde inte radera kommittén',
        },
        {
          duration: 8000,
        }
      ),
    onSettled: () => {
      queryClient.invalidateQueries([QK.committees]);
    },
  });
};

// Mutation function
const deleteCommittee = (id: string) =>
  fetchWithErrorThrowing(`/committees/${id}`, {
    method: 'DELETE',
    headers: new Headers({ 'content-type': 'application/json' }),
  });
