import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  UseFormReturn,
} from 'react-hook-form';
import { ErrorMessages, ListboxWithSearch } from 'ck-components';

interface Props<T extends FieldValues> {
  parentForm: UseFormReturn<T>;
  optionsData: PathValue<T, Path<T>>[];
  labelFromOption?: (option: PathValue<T, Path<T>>) => string;
  name: Path<T>;
  horizontal?: boolean | undefined;
  label: string;
  description?: string;
}

/**
 * MultiSelectDropdown component that allows selecting multiple options from a dropdown menu
 */
export const MultiSelectDropdown = <T extends FieldValues>({
  optionsData,
  name,
  parentForm,
  description,
  label,
  labelFromOption,
}: Props<T>) => {
  const { control, formState } = parentForm;
  console.log(formState.errors);
  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <>
          <ListboxWithSearch
            labelFromOption={labelFromOption}
            parentForm={parentForm}
            name={name}
            formLabel={label}
            description={description}
            optionsData={optionsData}
          />
          <ErrorMessages
            name={name}
            errors={formState.errors}
            takeUpSpace={false}
          />
        </>
      )}
    />
  );
};
