import { FieldValues, useForm } from 'react-hook-form';
import {
  Form,
  FormLabel,
  FormTextInput,
  InformationModal,
  InformationModalProps,
} from 'ck-components';

import { useCreateAdmin } from 'ck-queries';

interface FormValues extends FieldValues {
  email: string;
}

export const AddAdminModal = (
  props: Omit<InformationModalProps, 'children'> & { divisionId: string }
) => {
  const M_createAdmin = useCreateAdmin();
  const form = useForm<FormValues>();

  const { handleSubmit, reset } = form;

  const onSubmit = ({ email }: FormValues) => {
    M_createAdmin.mutateAsync({ id: props.divisionId, email }).then(() => {
      // Close modal
      props.setIsOpen(false);

      // Reset form
      reset();
    });
  };

  const onCancel = () => {
    reset();
    props.setIsOpen(false);
  };

  return (
    <InformationModal
      {...props}
      customStyle='w-[692px]'
      onClose={reset}
      showCloseButton={false}
    >
      <Form
        submitButtonLabel='Skicka inbjudan'
        showCancelButton
        form={form}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={onCancel}
        className='mt-5'
        centerButtons
        isSubmitting={M_createAdmin.isLoading}
      >
        <p>
          En inbjudan om att skapa ett konto kommer att skickas till angiven
          e-postadress. Inbjudan är aktiv under en vecka.
        </p>
        <FormLabel label='E-POSTADRESS'>
          <FormTextInput
            name='email'
            parentForm={form}
            errorsAlwaysTakeUpSpace={false}
            options={{
              required: 'E-postadress måste anges',
              pattern: {
                value: /^\S+@\S+$/i,
                message: 'Ogiltig e-postadress',
              },
            }}
          />
        </FormLabel>
      </Form>
    </InformationModal>
  );
};
