import { Navigate, NavigateProps } from 'react-router-dom';

import { PageName } from 'ck-global-types';

export interface TypedNavigateProps extends NavigateProps {
  to: PageName;
}
/**
 * Typed wrapper for current location. This is used to ensure that the user can only navigate to valid pages.
 */
export function TypedNavigate({
  to,
  replace,
  state,
  relative,
}: TypedNavigateProps) {
  return (
    <Navigate to={to} replace={replace} state={state} relative={relative} />
  );
}
