import {
  ChangePasswordInputField,
  FieldLabel,
  Form,
  InformationModal,
  PasswordInput,
  Tag,
} from 'ck-components';
import {
  PutChangePasswordRequestV1,
  roleDisplayNameMap,
  useAuth,
  usePasswordChange,
} from 'ck-queries';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useState } from 'react';

interface ChangePasswordFormValues extends PutChangePasswordRequestV1 {
  confirmPassword: string;
}

/**
 * The card displaying user information on the settings page.
 * Also displays a modal allowing the user to change password.
 */

export function CurrentUserCard() {
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const { user } = useAuth();
  const M_changePassword = usePasswordChange();

  // ?: Refactor to handle several committees when BE is ready
  const role = user?.roles[0];
  const divisionName = user?.divisions?.find((d) => d.id)?.name;
  const committeeName = user?.committees?.find((c) => c.id)?.name;

  const form = useForm<ChangePasswordFormValues>();

  const { handleSubmit, getValues, reset } = form;

  const closeModalAndResetForm = () => {
    reset();
    setShowChangePasswordModal(false);
  };

  const onSubmit: SubmitHandler<ChangePasswordFormValues> = async ({
    currentPassword,
    newPassword,
  }) => {
    M_changePassword.mutateAsync({ currentPassword, newPassword }).then(
      closeModalAndResetForm
    );
  };

  return (
    <>
      <InformationModal
        modalTitle='Byt lösenord'
        isOpen={showChangePasswordModal}
        setIsOpen={closeModalAndResetForm}
        showCloseButton={false}
        customStyle='w-2/6 min-w-fit'
      >
        <Form
          onSubmit={handleSubmit(onSubmit)}
          submitButtonLabel={'Spara'}
          showCancelButton
          onCancel={closeModalAndResetForm}
          form={form}
          isSubmitting={M_changePassword.isLoading}
          centerButtons
          ignoreGap
        >
          <PasswordInput
            label='Nuvarande lösenord'
            placeholder='Nuvarande lösenord'
            parentForm={form}
            name='currentPassword'
          />

          <PasswordInput
            label='Nytt lösenord'
            placeholder='Nytt lösenord'
            name='newPassword'
            parentForm={form}
            minLength={6}
            options={{
              validate: (value) =>
                value !== getValues('currentPassword') ||
                'Ditt nya lösenord får inte vara samma som det gamla',
            }}
          />

          <PasswordInput
            label='Bekräfta lösenord'
            placeholder='Bekräfta lösenord'
            name='confirmPassword'
            parentForm={form}
            minLength={6}
            options={{
              validate: {
                inputsAreTheSame: (value) =>
                  value === getValues('newPassword') ||
                  'Lösenorden måste vara samma',
                notTheSameAsOld: (value) =>
                  value !== getValues('currentPassword') ||
                  'Ditt nya lösenord får inte vara samma som det gamla',
              },
            }}
          />
        </Form>
      </InformationModal>
      <div className='flex min-w-[500px] flex-col gap-8'>
        <div className='flex flex-row justify-between gap-10'>
          <div className='flex flex-col gap-2'>
            <FieldLabel>Användare</FieldLabel>
            <p>{user?.email}</p>
          </div>
          <Tag
            label={role ? roleDisplayNameMap[role] : ''}
            customStyle='bg-sky-500'
          />
        </div>
        {/* Hide division name if the user is a moderator */}
        {divisionName && !committeeName && (
          <div className='flex flex-col gap-2'>
            <FieldLabel>Sektion</FieldLabel>
            <p>{divisionName}</p>
          </div>
        )}
        {committeeName && (
          <div className='flex flex-col gap-2'>
            <FieldLabel>Kommittée</FieldLabel>
            <p>{committeeName}</p>
          </div>
        )}
        <ChangePasswordInputField
          onEditPressCallback={() => setShowChangePasswordModal(true)}
        />
      </div>
    </>
  );
}
