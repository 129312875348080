import {
  ColorPicker,
  HiddenInput,
  SubTitle,
  TopGraphics,
  UploadInput,
} from 'ck-components';
import { darkenColor, lightenColor } from 'ck-utils';

import { ThemeFormData } from 'ck-queries';
import { UseFormReturn } from 'react-hook-form';
import { useCallback } from 'react';

interface Props {
  form: UseFormReturn<ThemeFormData>;
}

export const UtseendeSection = ({ form }: Props) => {
  const { register, watch } = form;

  // Watch for changes to the color TopGraphic pickers
  const leftPart = watch('theme.palette.section.events.topGraphic.leftPart');
  const middlePart = watch(
    'theme.palette.section.events.topGraphic.middlePart'
  );
  const rightPart = watch('theme.palette.section.events.topGraphic.rightPart');

  const lighten = useCallback(
    (value: string) => (value !== '#222D34' ? lightenColor(value, 0.1) : value),
    []
  );

  const darken = useCallback((value: string) => darkenColor(value, 0.6), []);

  return (
    <div className=''>
      <SubTitle label='2. Utseende' />
      <UploadInput
        name='image'
        parentForm={form}
        accept='.jpeg, .jpg, .png'
        label='Bild'
        description='Rekommenderad ratio: 600x650, 12x13'
        emptyState='Ladda upp bild'
      />
      <div className='flex flex-wrap'>
        <ColorPicker
          label='Bakgrund'
          name='theme.palette.layout.background'
          form={form}
        />
        <HiddenInput
          name='theme.palette.layout.backgroundSecondary'
          mirror='theme.palette.layout.background'
          beforeInsertion={lighten}
          form={form}
        />
        <input
          type='hidden'
          {...register('theme.palette.layout.backgroundSecondary')}
        />
        <ColorPicker
          label='Knappar/Ikoner'
          name='theme.palette.section.home.main'
          form={form}
        />
        {/* Inputs that are derived from the ColorPicker above */}
        <HiddenInput
          name='theme.palette.primary.icon'
          mirror='theme.palette.section.home.main'
          form={form}
        />
        <HiddenInput
          name='theme.palette.secondary.main'
          mirror='theme.palette.section.home.main'
          form={form}
        />
        <HiddenInput
          name='theme.palette.section.events.main'
          mirror='theme.palette.section.home.main'
          form={form}
        />
        <HiddenInput
          name='theme.palette.section.restaurants.main'
          mirror='theme.palette.section.home.main'
          form={form}
        />
        <HiddenInput
          name='theme.palette.section.workout.main'
          mirror='theme.palette.section.home.main'
          form={form}
        />
        <HiddenInput
          name='theme.palette.section.home.low_constrast'
          mirror='theme.palette.section.home.main'
          form={form}
          beforeInsertion={darken}
        />
        <HiddenInput
          name='theme.palette.section.workout.low_constrast'
          mirror='theme.palette.section.home.main'
          form={form}
          beforeInsertion={darken}
        />
      </div>
      <div className='flex'>
        <ColorPicker
          label='Illustration vänster'
          name='theme.palette.section.events.topGraphic.leftPart'
          form={form}
        />
        <HiddenInput
          name='theme.palette.section.restaurants.topGraphic.leftPart'
          mirror='theme.palette.section.events.topGraphic.leftPart'
          form={form}
        />
        <ColorPicker
          label='Illustration mitten'
          name='theme.palette.section.events.topGraphic.middlePart'
          form={form}
        />
        <HiddenInput
          name='theme.palette.section.restaurants.topGraphic.middlePart'
          mirror='theme.palette.section.events.topGraphic.middlePart'
          form={form}
        />
        <ColorPicker
          label='Illustration höger'
          name='theme.palette.section.events.topGraphic.rightPart'
          form={form}
        />
        <HiddenInput
          name='theme.palette.section.restaurants.topGraphic.rightPart'
          mirror='theme.palette.section.events.topGraphic.rightPart'
          form={form}
        />
      </div>
      <TopGraphics left={leftPart} middle={middlePart} right={rightPart} />
    </div>
  );
};
