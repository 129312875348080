import { Tooltip as ImportedToolTip } from 'react-tooltip';
import { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  id: string;
  content: string;
}

export const OurTooltip = ({ id, content, children }: Props) => {
  return (
    <>
      <ImportedToolTip id={id} />
      <a data-tooltip-content={content} data-tooltip-id={id}>
        {children}
      </a>
    </>
  );
};
