import { QK } from 'ck-queries';
import { ThemeDto } from './types';
import { fetchWithErrorThrowing } from 'ck-utils';
import { formatThemeData } from './localUtils';
import { useQuery } from '@tanstack/react-query';

// Query hook
export const useThemeHistory = () =>
  useQuery({
    queryKey: QK.themes.history,
    queryFn: getThemesHistory,
  });

// Query function
export async function getThemesHistory(): Promise<ThemeDto[]> {
  const res = await fetchWithErrorThrowing('/themes/history', {
    method: 'GET',
  });

  const data: { themes: ThemeDto[] } = await res.json();

  return formatThemeData(data.themes);
}
