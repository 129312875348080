import { PropsWithChildren } from 'react';

export const Description = ({
  text,
  children,
}: { text?: string } & PropsWithChildren) => {
  return (
    <div className='mt-1 w-full overflow-ellipsis text-left text-base text-sm  font-light text-gray-400'>
      {text ?? children}
    </div>
  );
};
