import { ConfirmationModal, ConfirmationModalProps } from 'ck-components';

import { useDeleteAdmin } from 'ck-queries';

interface Props extends Omit<ConfirmationModalProps, 'children'> {
  name: string;
  id: string;
}

export const RemoveAdminModal = (props: Props) => {
  const M_deleteAdmin = useDeleteAdmin();

  const onConfirmation = () => {
    M_deleteAdmin.mutate(props.id);
  };

  return (
    <ConfirmationModal
      {...props}
      confirmationLabel='Ta bort'
      isDelete
      onConfirmation={onConfirmation}
      customStyle=''
      isLoading={M_deleteAdmin.isLoading}
    >
      <p>
        Är du säker på att du vill ta bort{' '}
        <span className='font-bold'>{props.name}</span> som admin?
      </p>
    </ConfirmationModal>
  );
};
