import { GetHostsResponse, QK } from 'ck-queries';

import { fetchWithErrorThrowing } from 'ck-utils';
import { useQuery } from '@tanstack/react-query';

export const useEventsHosts = () => {
  return useQuery({
    queryKey: [QK.events.hosts],
    queryFn: () => getEventsHosts(),
  });
};

async function getEventsHosts(): Promise<GetHostsResponse['hosts']> {
  const res = await fetchWithErrorThrowing(`/events/hosts`, {
    method: 'GET',
  });

  const data: GetHostsResponse = await res.json();
  const hosts = data.hosts;
  // Sort hosts alphabetically by name
  hosts.sort((a, b) => a.name.localeCompare(b.name));

  return hosts;
}
