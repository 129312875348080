import { useMemo } from 'react';
import { useUser } from 'ck-queries';

export const useHasPaymentReceiverReportFunctionType = () => {
  const Q_user = useUser();
  const paymentReceiverReport = useMemo(
    () => Q_user.data?.functions.find((ft) => ft === 'PaymentReceiverReport'),
    [Q_user.data]
  );

  return paymentReceiverReport;
};
