import { ErrorMessages, FormLabelWithCount } from 'ck-components';

import { AnswersFormKey } from './AnswersGroup';
import { PostPollsRequest } from 'ck-queries';
import { ReactSVG } from 'react-svg';
import { UseFormReturn } from 'react-hook-form';
import { xmark } from 'ck-assets';

interface Props {
  showEditable: boolean;
  inputState: string;
  setInputState: React.Dispatch<React.SetStateAction<string>>;
  parentForm: UseFormReturn<PostPollsRequest>;
  formValueKey: AnswersFormKey;
  commitAnswer: () => void;
  deleteAnswer: (idx: number) => void;
  answersList: string[];
  inputRef: React.RefObject<HTMLInputElement>;
  addAnswer: (inputRef: React.RefObject<HTMLInputElement>) => void;
}
// TODO fix lol
// NOTE: This component is chaos. Needs to be broken down into smaller components.
export const Answers = ({
  showEditable,
  inputRef,
  answersList = [],
  inputState,
  setInputState,
  commitAnswer,
  deleteAnswer,
  addAnswer,
  parentForm,
  formValueKey,
}: Props) => {
  return (
    <div className='ml-8'>
      <FormLabelWithCount
        label={formValueKey === 'answersSwedish' ? 'Svar' : 'Answers'}
        name={formValueKey}
        parentForm={parentForm}
        description='Max 34 tecken. Minst två svar.'
      />
      {/* Hidden input to still get useForm errors */}
      <input
        type='hidden'
        {...parentForm.register(formValueKey, {
          validate: ({ length }) => length >= 2 || 'Minst två svar måste anges',
        })}
      />
      <div className='flex flex-col items-center justify-between gap-2'>
        {/* ANSWERS LIST */}
        {answersList?.map((value, idx) => (
          <div
            key={`${value}-${idx}`}
            className='relative flex w-full flex-row'
          >
            <ConnectorBorder />
            <div className='flex h-14 w-full items-center justify-between overflow-hidden rounded border border-gray-300 p-3 px-4'>
              {value}
              <ReactSVG
                src={xmark}
                className='w-6 min-w-[24px] cursor-pointer'
                onClick={() => deleteAnswer(idx)}
              />
            </div>
          </div>
        ))}
        {/* EDITABLE INPUT FIELD */}
        {showEditable && (
          <div className='relative w-full'>
            <ConnectorBorder />
            <input
              maxLength={34} // Limited to be able to fit one row in the app
              ref={inputRef}
              placeholder={
                formValueKey === 'answersSwedish'
                  ? 'Skriv in svar'
                  : 'Enter answer'
              }
              type='text'
              onBlur={commitAnswer}
              onKeyDown={({ key }) => {
                if (key === 'Enter') commitAnswer();
              }}
              value={inputState}
              className=' h-14 w-full rounded border border-gray-300 p-2 px-4 focus:outline-sky-500'
              onChange={(e) => setInputState(e.target.value)}
            />
          </div>
        )}
        {/* ADD ANSWER BUTTON */}
        {answersList?.length < 4 &&
        !(answersList?.length === 3 && showEditable) ? (
          <button
            type='button'
            onClick={() => addAnswer(inputRef)}
            className='mt-2 h-14 w-full rounded border-2 border-dashed bg-[#FAFAFA] p-2 text-[#ABB4B9] focus:outline-sky-500'
          >
            {formValueKey === 'answersSwedish'
              ? '+ Lägg till svar'
              : '+ Add answer'}
          </button>
        ) : null}
        <ErrorMessages
          errors={parentForm.formState.errors}
          name={formValueKey}
          takeUpSpace={true}
        />
      </div>
    </div>
  );
};

// Simple border to visually group the answers with the question
//
// Visually:
// Question
// |-- Answer
// |-- Answer
// |-- Answer
//
const ConnectorBorder = () => (
  <div className='absolute bottom-1/2 -ml-4 flex h-[100px] items-end border-r border-gray-300'>
    <div className='absolute h-1 w-4 border-b border-gray-300' />
  </div>
);
