import RowComponent, { RowComponentActionProps } from './RowComponent';

import clsx from 'clsx';

export type ArrElement<T> = T extends (infer ElementType)[]
  ? ElementType
  : never;
export interface TableProps<T> extends RowComponentActionProps {
  tableColHeadings: string[];
  tableRowDataPaths: Array<keyof ArrElement<T>>;
  data: T;

  customStyleHeaders?: string;
}

/**
 * Our styled and customized dynamic data table. Accepts a data object of any size and displays it
 * in accordance with the design mock.
 *
 * @param tableColHeadings an array of strings which will be rendered as table headings
 * @param tableRowDataPaths an array of strings containing the paths which the data will map against
 * @param data the data object to be rendered within the table
 * @param onRowClick an optional callback which is called when a row has been Clicked
 * @param onDeleteClick an optional callback with is called when the trash icon is Clicked
 * @param onCopyClick an optional callback with is called when the copy icon is Clicked
 * @param onDownloadClick an optional callback with is called when the copy icon is Clicked
 * @param conditionDisableCopyClick condition to hide copy-button depending on row data
 * @param conditionDisableEditClick condition to hide edit-button depending on row data
 */

export function Table<T extends Array<{ [key in keyof ArrElement<T>]: any }>>({
  tableColHeadings,
  tableRowDataPaths,
  data,
  onRowClick,
  customStyleHeaders,
  onDeleteClick,
  onEditClick,
  onCopyClick,
  conditionDisableEditClick,
  conditionDisableCopyClick,
  onDownloadClick,
  customLastColumn,
  onCustomClick,
}: TableProps<T>) {
  const editFunctionExists =
    Boolean(onDeleteClick) ||
    Boolean(onCustomClick) ||
    Boolean(onEditClick) ||
    Boolean(onCopyClick) ||
    Boolean(onDownloadClick);
  // If we have a delete or edit function, add 'ÄNDRA' in the last heading
  const tableHeadings = editFunctionExists
    ? [...tableColHeadings, 'ÄNDRA']
    : tableColHeadings;

  return (
    <div className='relative w-full'>
      <table className='w-full table-fixed border-separate border-spacing-y-2 text-left'>
        <thead>
          <tr>
            {tableHeadings.map((heading, index) => (
              <th
                key={`${heading}-${index}`}
                scope='col'
                className={clsx(
                  'tracking-gray-800 mb-2 mt-7 h-6 whitespace-nowrap px-4 text-left text-base font-light uppercase text-gray-800 first:pl-7 last:pr-7',
                  customStyleHeaders,
                  editFunctionExists && 'last:text-end'
                )}
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='text-lg'>
          {data?.map((dataEntry, index) => (
            <RowComponent
              key={`${Object.values(dataEntry)[0]}-${index}`}
              data={dataEntry}
              dataPaths={tableRowDataPaths}
              onRowClick={onRowClick}
              onDeleteClick={onDeleteClick}
              onEditClick={onEditClick}
              onCopyClick={onCopyClick}
              conditionDisableEditClick={conditionDisableEditClick}
              conditionDisableCopyClick={conditionDisableCopyClick}
              onDownloadClick={onDownloadClick}
              customLastColumn={customLastColumn}
              onCustomClick={onCustomClick}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
