import { PostEditCommittee, QK } from 'ck-queries';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { fetchWithErrorThrowing } from 'ck-utils';
import toast from 'react-hot-toast';

export const useEditCommittee = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: PostEditCommittee & { id: string }) =>
      toast.promise(
        editCommittee(data),
        {
          loading: 'Laddar...',
          success: 'Kommitté redigerad',
          error: 'Kunde inte redigera kommitté',
        },
        {
          duration: 8000,
        }
      ),
    onSettled: () => {
      queryClient.invalidateQueries([QK.committees]);
    },
  });
};

function editCommittee(data: PostEditCommittee & { id: string }) {
  return fetchWithErrorThrowing(`/committees/${data.id}`, {
    method: 'PUT',
    body: JSON.stringify({ ...data }),
    headers: new Headers({ 'content-type': 'application/json' }),
  });
}
