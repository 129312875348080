import { CommitteeFormatted, DivisionFormatted } from 'ck-queries';

export const organisationsTableStructure: OrganisationsTableStructure[] = [
  { title: 'Organisationer', dataPath: 'name' },
  { title: 'Kommittéer', dataPath: 'committees' },
  { title: 'Admin', dataPath: 'admins' },
  { title: 'Organisationsnummer', dataPath: 'orgNumber' },
];

export type OrganisationsTableStructure = {
  title: 'Organisationer' | 'Kommittéer' | 'Admin' | 'Organisationsnummer';
  dataPath: keyof DivisionFormatted;
};

export const committeeTableStructure: DivisionsTableStructure[] = [
  { title: 'Kommittéer', dataPath: 'name' },
  { title: 'Moderator', dataPath: 'activeEmail' },
  { title: 'Status', dataPath: 'status' },
  { title: 'Betalda event', dataPath: 'paidEvents' },
];

export const committeeTableStructureWithOrg: DivisionsTableStructure[] = [
  { title: 'Kommittéer', dataPath: 'name' },
  { title: 'Moderator', dataPath: 'activeEmail' },
  { title: 'Status', dataPath: 'status' },
  { title: 'Betalda event', dataPath: 'paidEvents' },
  { title: 'Org-nummer', dataPath: 'orgNumber' },
];

export type DivisionsTableStructure = {
  title: 'Kommittéer' | 'Moderator' | 'Status' | 'Betalda event' | 'Org-nummer';
  dataPath: keyof CommitteeFormatted;
};
