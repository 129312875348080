import { PushNotification } from './types';
import { QK } from 'ck-queries';
import { fetchWithErrorThrowing } from 'ck-utils';
import { formatPushNotifications } from './localUtils';
import { useQuery } from '@tanstack/react-query';

// Query hook
export const useNotificationsDraft = () =>
  useQuery({
    queryKey: QK.notifications.draft,
    queryFn: getNotificationsDraft,
  });

// Query function
export async function getNotificationsDraft() {
  const res = await fetchWithErrorThrowing('/notifications/draft', {
    method: 'GET',
  });

  const data: { scheduledNotifications: PushNotification[] } = await res.json();

  return data.scheduledNotifications;
}

export const getNotificationsDraftFormatted = () =>
  getNotificationsDraft().then(formatPushNotifications);
