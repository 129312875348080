import { QK } from 'ck-queries';
import { fetchWithErrorThrowing } from 'ck-utils';
import { useQuery } from '@tanstack/react-query';

interface EventsUserTypesResponse {
  userTypes: string[];
}

export const useEventsUserTypes = () => {
  return useQuery({
    queryKey: QK.events.userTypes,
    queryFn: () => getEventsUserTypes,
  });
};

// Query Function

const getEventsUserTypes = (): Promise<string[]> =>
  fetchWithErrorThrowing('/events/user-types', { method: 'GET' })
    .then((res) => res.json())
    .then(({ userTypes }: EventsUserTypesResponse) => userTypes);
