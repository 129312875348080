import { ConfirmationModal, Form } from 'ck-components';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  ThemeFormData,
  ThemePrefill,
  useThemeCreate,
  useThemeEdit,
} from 'ck-queries';
import { getSavedThemeValues, localStorageTyped } from 'ck-utils';
import { useEffect, useState } from 'react';

import { CountdownSection } from './components/CountdownSection';
import { TemaSection } from './components/TemaSection';
import { UtseendeSection } from './components/UtseendeSection';
import dayjs from 'dayjs';
import { defaultThemeValues } from 'ck-features';
import { useNavigate } from 'react-router-dom';

interface Props {
  handleFormChange: (config: ThemeFormData) => void;
  prefill?: ThemePrefill;
  isCopy?: boolean;
  isEdit?: boolean;
}

/**
 * The form used to create custom themes.
 * Split into sections for readability while each section still uses the same form.
 * Changes are propagated to the parent component through the handleFormChange prop.
 * This allows the parent component to display a preview of the theme with real-time values.
 */

export function CreateThemeForm({
  handleFormChange,
  prefill,
  isCopy,
  isEdit,
}: Props) {
  // Hooks
  const navigate = useNavigate();
  const M_CreateTheme = useThemeCreate();
  const M_EditTheme = useThemeEdit(prefill?.id ?? '');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  // Setup form hook
  const form = useForm<ThemeFormData>({
    mode: 'onChange',
    defaultValues: { ...(prefill ?? getSavedThemeValues()) },
  });
  const { watch, reset } = form;

  // Watch all form values that are needed to display
  const start = form.watch('start');
  const end = form.watch('end');

  // Subscribe parent component to form changes to display theme in preview
  useEffect(() => {
    const sub = watch((data) => handleFormChange(data as ThemeFormData));
    return () => sub.unsubscribe();
  }, [watch, handleFormChange]);

  const cleanForm = () => {
    localStorageTyped.remove('FORM_CREATE_THEME');
    reset(defaultThemeValues);
  };

  // Submit handler
  const onSubmit: SubmitHandler<ThemeFormData> = (data) => {
    if (isEdit) {
      M_EditTheme.mutateAsync(data).then(() => {
        // Reset the form
        cleanForm();
        navigate('/themes/archive');
        window.location.reload();
      });
    } else {
      M_CreateTheme.mutateAsync(data).then(() => {
        // Reset the form
        cleanForm();
        navigate('/themes/archive');
      });
    }
  };

  const onSaveDraft = () => {
    form.setValue('status', 'draft');
    form.handleSubmit(onSubmit)();
  };

  return (
    <Form
      buttonType='button'
      submitButtonLabel={'Gå vidare'}
      form={form}
      formLocalStorageKey={!isCopy && !isEdit ? 'FORM_CREATE_THEME' : undefined}
      onSubmit={() => {
        form.setValue('status', 'upcoming');
        setIsConfirmationModalOpen(true);
      }}
      onSaveDraft={onSaveDraft}
      onCleanForm={cleanForm}
    >
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onConfirmation={form.handleSubmit(onSubmit)}
        modalTitle={'Schemalägg tema'}
        confirmationLabel={'Schemalägg'}
      >
        <p className='max-w-md text-lg'>
          Ditt tema kommer synas i appen mellan den
          <br />
          <span className='font-bold'>
            {dayjs(start).format('D MMM YYYY, kl. HH:mm')} -{' '}
            {dayjs(end).format('D MMM YYYY, kl. HH:mm')}
          </span>
          .
          <br />
          Vill du schemalägga temat nu?
        </p>
      </ConfirmationModal>
      {/* SECTIONS */}
      {/* Every section uses the same form but is split for readability */}
      <div className='flex flex-col gap-8'>
        <TemaSection form={form} />
        <UtseendeSection form={form} />
        <CountdownSection form={form} />
      </div>
    </Form>
  );
}
