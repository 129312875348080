import { ChangeEvent } from 'react';
import clsx from 'clsx';

type RadioProps<T extends string | number | readonly string[]> = {
  selectOptions: Array<{ label: string; value: T }>;
  selectedValue: T;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  label?: string;
};

export const ToggleRadioButtons = <
  T extends string | number | readonly string[]
>({
  disabled,
  selectOptions: selectOptions,
  selectedValue,
  onChange,
}: RadioProps<T>) => {
  return (
    <>
      <div className='flex gap-5 py-2'>
        {selectOptions.map((option) => (
          <label
            key={`${option.value}`}
            className={clsx('inline-flex', !disabled && 'cursor-pointer')}
          >
            <div
              className={clsx(
                'flex h-6 w-6 shrink-0 rounded-full border-2 border-sky-500 bg-sky-500',
                disabled && 'border-gray-300'
              )}
            >
              <input
                type='radio'
                disabled={disabled}
                value={option.value}
                checked={selectedValue === option.value}
                onChange={onChange}
                className='h-full w-full appearance-none rounded-full border-2 border-white bg-white checked:bg-sky-500 enabled:cursor-pointer'
              />
            </div>
            <span className={clsx('ml-2', disabled && 'text-gray-400')}>
              {option.label}
            </span>
          </label>
        ))}
      </div>
    </>
  );
};
