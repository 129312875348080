import { GetFunctionTypesResponse } from './types';
import { QK } from 'ck-queries';
import { fetchWithErrorThrowing } from 'ck-utils';
import { useQuery } from '@tanstack/react-query';

export const useGetFunctionTypes = () => {
  return useQuery<GetFunctionTypesResponse>({
    queryKey: QK.functionTypes,
    queryFn: getFunctionTypes,
  });
};

export const getFunctionTypes = async (): Promise<GetFunctionTypesResponse> => {
  const res = await fetchWithErrorThrowing('/function-types', {
    method: 'GET',
  });
  const data = await res.json();
  return data;
};
