import { InformationModal } from 'ck-components';
import { PropsWithChildren } from 'react';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

/**
 * A modal with information about how to create an event.
 *
 * @param isOpen whether the modal is open or not
 * @param setIsOpen function to set whether the modal is open or not
 * @returns a modal with information about how to create an event
 * @example
 **/
export const EventsCreateInfoModal = ({ isOpen, setIsOpen }: Props) => {
  // Generalize these components if needed for other components
  const ModalTitle = ({ children }: PropsWithChildren) => (
    <div className='mb-3 text-2xl font-semibold'>{children}</div>
  );

  const ModalSubTitle = ({ children }: PropsWithChildren) => (
    <div className='mb-2 text-lg font-light tracking-custom'>{children}</div>
  );

  const ModalParagraph = ({ children }: PropsWithChildren) => (
    <p className='mb-6'>{children}</p>
  );

  const ModalSubParagraph = ({ children }: PropsWithChildren) => (
    <p className='mb-2'>{children}</p>
  );

  return (
    <InformationModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      customStyle='w-[720px]'
    >
      <ModalTitle>Skapa event till Chalmers Kårapp</ModalTitle>
      <div className='mb-4'>
        Här kan du som arrangör lägga upp event som riktar sig mot Chalmers
        studenter.
      </div>
      <ModalSubTitle>EVENTET MÅSTE UPPFYLLA NEDAN KRITERIER:</ModalSubTitle>
      <ModalParagraph>
        {/* <ModalSubParagraph>
          • Ska riktas antingen till alla kårens medlemmar eller till en eller
          flera sektioner.
          <br />
        </ModalSubParagraph> */}
        <ModalSubParagraph>
          • Ska ha textstycken på både svenska och engelska, oavsett eventets
          natur. Alla medlemmar ska kunna ta till sig informationen oavsett
          vilket språk eventet hålls på.
          <br />
        </ModalSubParagraph>
        <ModalSubParagraph>
          • Ska ha er som huvudarrangör och detta ska framgå tydligt. T.ex. får
          ett företags logga inte var större än er egen logotyp, som alltid ska
          synas tydligt.
          <br />
        </ModalSubParagraph>
        <ModalSubParagraph>
          • Vara tydligt och inkluderande i språket (undvik för mycket interna
          uttryck och förkortningar) <br />
        </ModalSubParagraph>
        <ModalSubParagraph>
          • Om eventet inte uppfyller alla kriterier eller på andra sätt bryter
          mot kårens kommunikations- eller likabehandlingspolicier kan det komma
          att tas ned av superadmin.
        </ModalSubParagraph>
      </ModalParagraph>
      <ModalSubTitle>ATT SKAPA EVENT</ModalSubTitle>
      <ModalParagraph>
        <ModalSubParagraph>
          • Alla event som skapas via admingränssnittet kommer att publiceras
          direkt i Chalmers Kårapp och vara synliga för alla användare.
          <br />
        </ModalSubParagraph>
        <ModalSubParagraph>
          • Eventet kommer synas på både svenska och engelska beroende på vilket
          språk som är inställt i appen, därför måste du fylla i fälten i
          formuläret på båda språken.
          <br />
        </ModalSubParagraph>
        <ModalSubParagraph>
          • Du kan när som helst redigera eller ta bort ett event som är
          publicerat och aktivt i appen.
          <br />
        </ModalSubParagraph>
        <ModalSubParagraph>
          • När du publicerar ett event placeras det i listvyn under fliken
          “Aktiva” under “Arkiv” fram tills dess att slutdatumet för eventet
          passerat. Då flyttas eventet över till fliken “Historik” under “Arkiv”
          och tas bort från appen.
        </ModalSubParagraph>
      </ModalParagraph>
    </InformationModal>
  );
};
