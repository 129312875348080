import { ReactSVG } from 'react-svg';
interface Props {
  src: string;
  size?: number;
}

/**
 * ReactSVG grew in mysterious ways.
 * @param src icon source. use import from assets and plop it in here
 * @param size square-size of icon
 * @returns A perfectly square icon that takes up as much space as expected
 */
export const Icon = ({ src, size = 24 }: Props) => {
  return (
    <div className={`w-${size} h-${size}`}>
      <ReactSVG src={src} className={`w-${size} h-${size}`} fontSize={size} />
    </div>
  );
};
