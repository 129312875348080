import { downloadResponse, fetchWithErrorThrowing } from 'ck-utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { EventsParticipants } from './types';
import { GK } from 'ck-queries';
import toast from 'react-hot-toast';

export const useEventsDownloadParticipants = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (event: EventsParticipants) =>
      toast.promise(
        postDownloadParticipants(event.eventId, event.title, event.startDate),
        {
          loading: 'Laddar...',
          success: 'Deltagarlista exporterad!',
          error: 'Kunde inte exportera deltagarlistan.',
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: GK.events,
      });
    },
  });
};

async function postDownloadParticipants(
  eventId: string,
  title: string,
  startDate: string
) {
  return fetchWithErrorThrowing(`/events/${eventId}/participants/download`, {
    method: 'POST',
  }).then(async (response) => {
    downloadResponse(response, `${title}_${startDate}.csv`);
  });
}
