import { APIError } from 'ck-global-types';
import { QK } from 'ck-queries';
import { toast } from 'react-hot-toast';
import { useQueryClient } from '@tanstack/react-query';

export const useInitErrorHandling = (signOutCallback: () => void) => {
  // When a query/mutation fails, the retryLogic kicks in
  // After a set amount of failures it passes the error to the errorHandler
  const retryLogic = (retryCount: number, e: unknown) => {
    const err = e as APIError;

    // Let refresh-token retry 3 times
    // Default retry delay is 1sec, 2sec, 4sec
    if (retryCount > 2) {
      // Retries exhausted
      return false;
    }

    switch (err.errorType) {
      case 'AUTHENTICATION_ERROR': {
        // User session is not valid
        // Go straight to the errorHandler without retries
        return false;
      }
      case 'EXPIRED_TOKEN': {
        // Call refresh-token route
        queryClient.invalidateQueries([QK.refreshToken]);

        // Try original query/mutation again
        return true;
      }
    }

    // If unknown error send straight to errorHandler
    return false;
  };

  // Errors thrown in a fetch used within a query/mutation are caught here
  const errorHandler = (err: unknown) => {
    if (err instanceof APIError) {
      // At this point we know refresh retries have been exhausted
      if (err.status === 401) {
        signOutCallback();
      }

      // Special case for user not found while logged in
      if (
        err.status === 404 &&
        err.clientMessageSwedish === 'Användare kunde ej hittas'
      ) {
        signOutCallback();
      }

      // Always show clientMessage if it exists otherwise general error message
      if (err.clientMessageSwedish) {
        toast.error(err.clientMessageSwedish);
      } else if (err.errors) {
        // Show extra toast for every type of error provided
        Object.entries(err.errors).forEach((e) => {
          toast.error(e[1].join(', '));
        });
      } else {
        toast.error('Något gick fel');
      }

      return;
    }

    // Since we conform all errors to APIError this should never happen
    console.error(`${err}`);
  };

  const queryClient = useQueryClient();

  // Set default error handling on all queries and mutations
  queryClient.setDefaultOptions({
    queries: {
      retry: retryLogic,
      onError: errorHandler,
    },
    mutations: {
      retry: retryLogic,
      onError: errorHandler,
    },
  });
};
