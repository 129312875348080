import './index.css';

import { PathParams, RestRequest } from 'msw';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AuthProvider } from 'ck-queries';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import dayjs from 'dayjs';
import { router } from './router';
import sv from 'dayjs/locale/sv';

// Toggle for mock backend
const mockBackend = false;

if (process.env.NODE_ENV === 'development' && mockBackend) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./utils/mocks/browser');
  worker.start({
    // Filters away warnings for requests that are not to our backend
    // when running MSW.
    onUnhandledRequest(req: RestRequest<never, PathParams<string>>) {
      const regexp = new RegExp(`^${process.env.REACT_APP_BE_BASEURL}`);
      if (regexp.test(req.url.href))
        console.error(
          'Found an unhandled %s request to %s',
          req.method,
          req.url.href
        );
    },
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Set the locale to SV for dayjs globally
dayjs.locale(sv);

root.render(
  <React.StrictMode>
    {mockBackend && (
      <div className='absolute top-0 z-50 flex h-5 w-screen items-center justify-center bg-red-500 text-white'>
        USING LOCAL MOCK BACKEND
      </div>
    )}
    <QueryClientProvider client={new QueryClient()}>
      <AuthProvider>
        <Toaster toastOptions={{ duration: 5000 }} />
        <RouterProvider router={router} />
        <ReactQueryDevtools initialIsOpen={false} />
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
