import { FullScreenLoadingSpinner, MainTitle } from 'ck-components';

import { CreateAlarmnForm } from 'ck-features';
import { TypedNavigate } from 'ck-utils';
import { useAlarmPrefill } from 'ck-queries';
import { useParams } from 'react-router-dom';

interface Props {
  isCopy?: boolean;
}
export function AlarmCreatePage({ isCopy }: Props) {
  // Id provided by the route when editing a notification
  const { id } = useParams();
  const { data, isLoading } = useAlarmPrefill(id, Boolean(isCopy));

  // Get the values of the notification to edit
  const prefill = data?.prefill;

  if (isLoading) {
    return <FullScreenLoadingSpinner />;
  }

  if (!prefill && id) {
    // If a id exists without prefill, create a new alarm from scratch
    return (
      <TypedNavigate to='/push-notifications/alarm/create' replace={true} />
    );
  }

  const mainTitle = isCopy ? 'Kopiera larm' : 'Skapa larm';

  return (
    <div className='flex flex-1 flex-col'>
      <MainTitle label={mainTitle} customStyle='pb-8' />

      <CreateAlarmnForm prefill={prefill} isCopy={isCopy} />
    </div>
  );
}
