import { EventObj } from './types';
import { QK } from 'ck-queries';
import { fetchWithErrorThrowing } from 'ck-utils';
import { formatEvents } from './localUtils';
import { useQuery } from '@tanstack/react-query';

// Query hook
export const useEventsHistory = () =>
  useQuery({
    queryKey: QK.events.history,
    queryFn: getEventsHistory,
  });

// Query function
export async function getEventsHistory(): Promise<EventObj[]> {
  const res = await fetchWithErrorThrowing('/events/history', {
    method: 'GET',
  });

  const data: { events: EventObj[] } = await res.json();

  return formatEvents(data.events);
}
