import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QK } from 'ck-queries';
import { fetchWithErrorThrowing } from 'ck-utils';
import { toast } from 'react-hot-toast';

// Mutation hook
export const useNotificationsDelete = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (notificationId: string) =>
      toast.promise(
        deleteScheduledNotification(notificationId),
        {
          loading: 'Laddar...',
          success: 'Pushnotisen borttagen',
          error: 'Kunde inte ta bort pushnotisen',
        },
        {
          duration: 8000,
        }
      ),
    onSettled: () => {
      queryClient.invalidateQueries(QK.notifications.scheduled);
    },
  });
};

// Mutation function
export async function deleteScheduledNotification(notificationId: string) {
  return fetchWithErrorThrowing(`/notifications/scheduled/${notificationId}`, {
    method: 'DELETE',
  });
}
