import { EventIdName } from './types';
import { QK } from 'ck-queries';
import { fetchWithErrorThrowing } from 'ck-utils';
import { useQuery } from '@tanstack/react-query';

// Query hook
export const useEventsCategories = () => {
  return useQuery({
    queryKey: QK.events.categories,
    queryFn: getEventsCategories,
  });
};

// Query function
async function getEventsCategories(): Promise<EventIdName[]> {
  const res = await fetchWithErrorThrowing('/events/categories', {
    method: 'GET',
  });
  const data: { eventCategories: Array<{ id: string; name: string }> } =
    await res.json();

  return data.eventCategories;
}
