import { FieldValues, UseFormReturn } from 'react-hook-form';
import {
  ImageInLocalStorage,
  convertBase64ImageToFile,
} from './convertBase64ImageToFile';
import { LocalStorageTypes, localStorageTyped } from './localStorageTyped';
import { PostCreateEventV1, ThemeFormData } from 'ck-queries';
import {
  defaultAlarmValues,
  defaultEventValues,
  defaultPollValues,
  defaultPushNotificationValues,
  defaultThemeValues,
} from 'ck-features';

export const getSavedEventValues = () => {
  const storage = localStorageTyped.get('FORM_CREATE_EVENT');

  let imageAsFile: File | null = null;

  if (storage?.image && storage?.image?.base64String) {
    imageAsFile = convertBase64ImageToFile(storage.image);
  }

  // If the values are not set, return the default values
  return storage
    ? ({
        ...storage,
        image: imageAsFile,
        endTime: storage.endTime || defaultEventValues.endTime,
        startTime: storage.startTime || defaultEventValues.startTime,
        date: storage.date || defaultEventValues.date,
        targetUserType:
          storage.targetUserType || defaultEventValues.targetUserType,
      } as PostCreateEventV1)
    : defaultEventValues;
};

export const getSavedThemeValues = () => {
  // Get the saved values from localStorage
  const storage = localStorageTyped.get('FORM_CREATE_THEME');
  let imageAsFile: File | null = null;

  if (storage?.image && storage?.image?.base64String) {
    imageAsFile = convertBase64ImageToFile(storage.image);
  }

  // If the values are not set, return the default values
  return storage
    ? ({
        ...storage,
        image: imageAsFile,
        end: storage.end || defaultThemeValues.end,
        start: storage.start || defaultThemeValues.start,
        themeButtonEventId:
          storage.themeButtonEventId || defaultThemeValues.themeButtonEventId,
      } as ThemeFormData)
    : defaultThemeValues;
};

export const getSavedPushNotificationValues = () => {
  const storage = localStorageTyped.get('FORM_CREATE_NOTIFICATION');

  return storage ?? defaultPushNotificationValues;
};

export const getSavedAlarmValues = () => {
  const storage = localStorageTyped.get('FORM_CREATE_ALARM');
  return storage ?? defaultAlarmValues;
};

export const getSavedPollValues = () => {
  const storage = localStorageTyped.get('FORM_CREATE_POLL');

  let imageAsFile: File | null = null;

  if (storage?.image && storage?.image?.base64String) {
    imageAsFile = convertBase64ImageToFile(storage.image);
  }
  return storage ? { ...storage, image: imageAsFile } : defaultPollValues;
};

export const saveInLocalStorage = <T extends FieldValues>(
  formKey: keyof LocalStorageTypes,
  parentForm: UseFormReturn<T, any>
) => {
  const values = parentForm.getValues();
  const reader = new FileReader();

  // Save all values instantly
  localStorageTyped.set(formKey, values as any);

  if (values.image instanceof Blob) reader.readAsDataURL(values.image);

  // Add image data to saved data as soon as reader has loaded
  reader.addEventListener('load', () => {
    const image: ImageInLocalStorage = {
      base64String: reader.result as string,
      name: values.image?.name,
      type: values.image?.type,
    };

    // Get values then re-save with image overwritten from reader
    switch (formKey) {
      case 'FORM_CREATE_EVENT':
        localStorageTyped.set(formKey, {
          ...getSavedEventValues(),
          image,
        });
        break;
      case 'FORM_CREATE_THEME':
        localStorageTyped.set(formKey, {
          ...getSavedThemeValues(),
          image,
        });
        break;
      case 'FORM_CREATE_NOTIFICATION':
        localStorageTyped.set(formKey, getSavedPushNotificationValues());
        break;
      case 'FORM_CREATE_POLL':
        localStorageTyped.set(formKey, { ...getSavedPollValues(), image });
        break;
    }
  });
};
