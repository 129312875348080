export type ReportRangeType =
  | 'Idag'
  | 'Igår'
  | 'Denna månad'
  | 'Föregående månad'
  | 'Anpassad';

export const ReportRangeArray: ReportRangeType[] = [
  'Idag',
  'Igår',
  'Denna månad',
  'Föregående månad',
  'Anpassad',
];

export interface CommonReportFrom {
  from: string;
  to: string;
}
export type PostPayeeReportForm = CommonReportFrom;

export interface PostHostReportForm extends CommonReportFrom {
  committeeIds: string[];
}
