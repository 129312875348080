import { PostHostReportForm } from './types';
import { downloadResponse } from 'src/utils/downloadResponse';
import { fetchWithErrorThrowing } from 'ck-utils';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';

export const useReportHostDownload = () => {
  return useMutation({
    mutationFn: (data: PostHostReportForm) =>
      toast.promise(postReportHostDownload(data), {
        loading: 'Laddar...',
        success: 'Rapport exporterad',
        error: 'Kunde inte exportera rapport',
      }),
  });
};

const postReportHostDownload = async (data: PostHostReportForm) => {
  return fetchWithErrorThrowing('/report/host/download', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: new Headers({
      'content-type': 'application/json',
    }),
  })
    .then(async (response) => {
      downloadResponse(
        response,
        `arrangör(${data.committeeIds.length}st)_${data.from.split('T')[0]}_${
          data.to.split('T')[0]
        }`
      );
    })
    .catch((e) => console.log(e));
};
