import {
  QK,
  useNotificationsHistory,
  useNotificationsScheduled,
} from 'ck-queries';

import { formatAlarmPrefill } from 'ck-utils';
import { useQuery } from '@tanstack/react-query';

export const useAlarmPrefill = (
  notificationId: string | undefined,
  isCopy: boolean
) => {
  const { data: historyNotifications } = useNotificationsHistory();
  const { data: scheduledNotifications } = useNotificationsScheduled();

  const notifications = [
    ...(historyNotifications ?? []),
    ...(scheduledNotifications ?? []),
  ];

  return useQuery({
    queryKey: [...QK.alarm.prefill, notificationId, isCopy],
    queryFn: async () => {
      const maybeNotification = notifications.find(
        ({ id }) => id === notificationId
      );
      return formatAlarmPrefill(maybeNotification, isCopy);
    },
    enabled: true,
  });
};
