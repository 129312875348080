import { Info } from 'ck-assets';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';

interface TitleProps {
  label: string;
  customStyle?: string;
  infoIcon?: boolean;
  onClickInfoIcon?: () => void;
}

/**
 * A styled component for displayed main titles on pages/components.
 *
 * @param label the text to be displayed
 * @param customStyle optional styling
 * @param infoIcon optionally show info icon
 * @param onClickInfoIcon optional function to be called when info icon is clicked
 */

export function MainTitle({
  label,
  customStyle,
  infoIcon,
  onClickInfoIcon,
}: TitleProps) {
  return (
    <div
      className={clsx(
        'flex w-full items-center text-left text-3xl font-extrabold leading-loose',
        customStyle
      )}
    >
      {label}
      {infoIcon && (
        <ReactSVG
          src={Info}
          className={'ml-4 w-7 cursor-pointer text-sky-500'}
          onClick={onClickInfoIcon}
        />
      )}
    </div>
  );
}
