import EditIcon from 'src/assets/iconComponents/EditIcon';
import clsx from 'clsx';

interface ChangePasswordInputFieldProps {
  onEditPressCallback: () => void;
  customStyle?: string;
}

/**
 * This is a non-editable password input component. Used for the sole purpose of allowing the
 * user to change password at will. The callback called when the user presses the 'edit'-icon
 * should most likely render a modal (or some kind of form) where the user can change and edit
 * the current password.
 *
 * @param onEditPressCallback the callback called when the user presses the 'edit'-icon
 * @param customStyle optional additional styling for the field
 */

export function ChangePasswordInputField({
  onEditPressCallback,
  customStyle,
}: ChangePasswordInputFieldProps) {
  return (
    <div className={clsx('relative flex w-full flex-col', customStyle)}>
      <input
        className='border-formInputBorder h-14 w-full rounded border-2 border-solid pl-4'
        type='password'
        defaultValue={'abc123456789'}
        readOnly
      />
      <div
        onClick={onEditPressCallback}
        className='absolute top-4 right-4 h-6 w-6 hover:cursor-pointer'
      >
        <EditIcon />
      </div>
    </div>
  );
}
