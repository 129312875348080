import { NavigateOptions, useNavigate } from 'react-router-dom';

import { PageName } from 'ck-global-types';

// Define a new type for the custom navigate function that accepts a PageName for the first argument
type TypedNavigateFunction = (to: PageName, options?: NavigateOptions) => void;

/**
 * Custom hook that wraps the useNavigate hook and enforces the PageName type on the navigate function.
 * @returns { navigate: TypedNavigateFunction }
 */
export const useTypedNavigate = (): { navigate: TypedNavigateFunction } => {
  const rawNavigate = useNavigate();

  // Create a wrapper function to enforce the PageName type on the input
  const navigate: TypedNavigateFunction = (to, options) => {
    rawNavigate(to, options);
  };

  return { navigate };
};
