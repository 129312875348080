import { createFormData, fetchWithErrorThrowing } from 'ck-utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { GK } from 'ck-queries';
import { PostCreateEventV1 } from './types';
import toast from 'react-hot-toast';

export const useEventsEdit = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: PostCreateEventV1) =>
      toast.promise(putEditEvent(data, id), {
        loading: 'Laddar...',
        success: 'Event har redigerats',
        error: 'Kunde inte redigera eventet',
      }),
    onSuccess: () => {
      queryClient.refetchQueries(GK.events);
    },
  });
};

async function putEditEvent(data: PostCreateEventV1, id: string) {
  const formData = createFormData(data);

  return fetchWithErrorThrowing(`/events/${id}`, {
    method: 'PUT',
    body: formData,
  });
}
