import { createFormData, fetchWithErrorThrowing } from 'ck-utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { GK } from 'ck-queries';
import { ThemeFormData } from './types';
import toast from 'react-hot-toast';

export const useThemeEdit = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ThemeFormData) =>
      toast.promise(
        putEditTheme(data, id),
        {
          loading: 'Laddar...',
          success: 'Temat är uppdaterat',
          error: 'Kunde inte redigera temat',
        },
        {
          duration: 8000,
        }
      ),
    onSettled: () => {
      queryClient.invalidateQueries(GK.themes);
    },
  });
};

async function putEditTheme(
  { targetGroup, theme, ...rest }: ThemeFormData,
  id: string
): Promise<void> {
  const formData = createFormData({
    ...rest,
    targetGroup: targetGroup.group, // Only send the targetGroup.group as a string
    theme: JSON.stringify(theme), // Convert theme to string to fit backend spec
  });
  await fetchWithErrorThrowing(`/themes/${id}`, {
    method: 'PUT',
    body: formData,
  });
  return;
}
