import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  UseFormReturn,
} from 'react-hook-form';
import {
  SingleSelectDropdownList,
  SingleSelectDropdownListProps,
} from './SingleSelectDropdownList';

import { ErrorMessages } from '../ErrorMessagesNew/ErrorMessages';
import { FormLabelWithCount } from '../FormLabel/FormLabelWithCount';
import { Listbox } from '@headlessui/react';
import { SingleSelectDropdownButton } from './SingleSelectDropdownButton';
import clsx from 'clsx';
import { useMemo } from 'react';

export interface SingleSelectValueLabelPair<K> {
  value: K;
  label: string;
}
export interface SingleSelectDropdownProps<T extends FieldValues, K>
  extends Pick<SingleSelectDropdownListProps<K>, 'withSearch'> {
  form: UseFormReturn<T>;
  values: K[];
  hideValues?: K[];
  controlledConfig?: {
    selectedValue: PathValue<T, Path<T>>;
    onChange: (selected: K) => void;
  };
  labelFromValue: (value: K) => string;
  name: Path<T>;
  disabled?: boolean | undefined;
  label?: string;
  description?: string;
  errorsAlwaysTakeUpSpace?: boolean;
  emptyStateLabel?: string;
  onChangeEffect?: (selected: K) => void;
  defaultValue?: K;
}

/**
 *
 * @param form parent form
 * @param values values of options in dropdown
 * @param hideValues array of values to hide from dropdown (optional)
 * @param labelFromValue function to create label for value (optional)
 * @param name name of form-path
 * @param disabled disable the comopnent (optional)
 * @param label label for dropdown
 * @param description description for dropdown
 * @param defaultValue default value (optional)
 * @param controlledConfig object required to override config {selectedValue, onChange} (optional)
 * @param onChangeEffect additional effect executed after onChange (optional)
 * @returns
 */
export const SingleSelectDropdown = <T extends FieldValues, K>({
  form,
  values,
  hideValues = [],
  labelFromValue,
  name,
  disabled,
  label,
  description,
  errorsAlwaysTakeUpSpace,
  emptyStateLabel = label ? `Välj ${label.toLowerCase()}` : '',
  controlledConfig,
  defaultValue,
  onChangeEffect,
  ...dropdownListProps
}: SingleSelectDropdownProps<T, K>) => {
  const valueLabelPairs: SingleSelectValueLabelPair<K>[] = values.map((v) =>
    Object({ value: v, label: labelFromValue(v) })
  );
  const { control, formState, watch, setValue } = form;
  const selectedOption = watch(name);

  const displayLabel = useMemo(
    () =>
      controlledConfig
        ? labelFromValue(controlledConfig.selectedValue)
        : selectedOption
        ? labelFromValue(selectedOption)
        : undefined,
    [labelFromValue, controlledConfig, selectedOption]
  );

  const filteredValueLabelPairs = useMemo(
    () =>
      hideValues
        ? valueLabelPairs.filter(
            (vlp) => !hideValues.some((hv) => hv === vlp.value)
          )
        : valueLabelPairs,
    [hideValues, valueLabelPairs]
  );

  const decidedSelectedOption = useMemo(
    () => (controlledConfig ? controlledConfig.selectedValue : selectedOption),
    [controlledConfig, selectedOption]
  );
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={
        (defaultValue as PathValue<T, Path<T>>) ?? selectedOption ?? undefined
      }
      render={() => (
        <div className={clsx('relative w-full', !selectedOption && '-mb-1')}>
          <Listbox
            value={
              controlledConfig ? controlledConfig.selectedValue : selectedOption
            }
            disabled={disabled}
            onChange={(selected) => {
              controlledConfig
                ? controlledConfig.onChange(selected)
                : setValue(name, selected);

              onChangeEffect?.(selected);
            }}
          >
            {({ open }) => (
              <>
                {label && (
                  <FormLabelWithCount
                    label={label}
                    description={description}
                    parentForm={form}
                    name={name}
                  />
                )}
                <SingleSelectDropdownButton
                  name={name}
                  emptyStateLabel={emptyStateLabel}
                  disabled={disabled}
                  displayLabel={displayLabel}
                  open={open}
                />

                <div className='mt-1' />
                <SingleSelectDropdownList
                  {...dropdownListProps}
                  valueLabelPairs={filteredValueLabelPairs}
                  selectedOption={decidedSelectedOption}
                />
                <ErrorMessages
                  errors={formState.errors}
                  name={name}
                  takeUpSpace={errorsAlwaysTakeUpSpace}
                />
              </>
            )}
          </Listbox>
        </div>
      )}
    />
  );
};
