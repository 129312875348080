import {
  ConfirmationModal,
  Form,
  FormMultiLineTextInput,
  FormTextInput,
  SubTitle,
  TargetGroupDropdownSelector,
} from 'ck-components';
import { PostAlarm, translateTargetGroup, useAlarmCreate } from 'ck-queries';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  getSavedAlarmValues,
  localStorageTyped,
  useTypedNavigate,
} from 'ck-utils';

import { defaultAlarmValues } from './defaults';
import { useState } from 'react';

interface Props {
  prefill?: Omit<PostAlarm, 'targetGroup'>;
  isCopy?: boolean;
}

/**
 * The form used to create push notifications.
 */

export function CreateAlarmnForm({ prefill, isCopy }: Props) {
  // Hooks
  const { navigate } = useTypedNavigate();

  const M_CreateAlarm = useAlarmCreate();

  const form = useForm<PostAlarm>({
    mode: 'onChange',
    defaultValues: {
      ...(prefill ?? getSavedAlarmValues()),
    },
  });

  const { reset, watch } = form;
  const targetGroup = watch('targetGroup');

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const cleanForm = () => {
    localStorageTyped.remove('FORM_CREATE_ALARM');
    reset(defaultAlarmValues);
  };

  const onSubmit: SubmitHandler<PostAlarm> = async (data) => {
    M_CreateAlarm.mutateAsync(data).then(() => {
      cleanForm();
      navigate('/push-notifications/archive');
    });
  };

  return (
    <div className='max-w-7xl'>
      <Form
        formLocalStorageKey={!isCopy ? 'FORM_CREATE_ALARM' : undefined}
        onSubmit={() => setIsConfirmationOpen(true)}
        buttonType='button'
        submitButtonLabel={'Gå vidare'}
        submitButtonStyle='w-52'
        onCleanForm={cleanForm}
        form={form}
      >
        <ConfirmationModal
          isOpen={isConfirmationOpen}
          setIsOpen={setIsConfirmationOpen}
          onConfirmation={form.handleSubmit(onSubmit)}
          modalTitle={'Skicka larm'}
          confirmationLabel={'Skicka nu'}
          isDelete
        >
          {targetGroup && (
            <p className='max-w-md text-lg'>
              Du håller på att skicka en notis med <b>hög prio</b> som kommer
              att skickas till
              {` ${translateTargetGroup(targetGroup.group)} (${
                targetGroup.count
              } st)`}
              . Är du säker på att du vill skicka larmet?
            </p>
          )}
        </ConfirmationModal>
        <div className='flex flex-1 flex-row gap-14'>
          <div className='flex-1'>
            <SubTitle label='Svenska' />
            <FormTextInput
              label={'RUBRIK'}
              parentForm={form}
              maxLength={40}
              name='titleSwedish'
            />
            <FormTextInput
              description={'Visas i pushnotisen'}
              label={'KORT MEDDELANDE'}
              parentForm={form}
              maxLength={100}
              name='notificationMessageSwedish'
            />
            <FormMultiLineTextInput
              label={'LÅNGT MEDDELANDE'}
              description={'Visas i appens inkorgsvy'}
              parentForm={form}
              type='textfield'
              maxLength={1000}
              name='inboxMessageSwedish'
            />
          </div>
          <div className='flex-1'>
            <SubTitle label='English' />
            <FormTextInput
              label={'TITLE'}
              parentForm={form}
              maxLength={40}
              name='titleEnglish'
            />
            <FormTextInput
              label={'SHORT MESSAGE'}
              description={'Displayed in the push notification'}
              parentForm={form}
              maxLength={100}
              name='notificationMessageEnglish'
            />
            <FormMultiLineTextInput
              label={'LONG MESSAGE'}
              description={`Displayed in the app's inbox`}
              parentForm={form}
              maxLength={1000}
              name='inboxMessageEnglish'
            />
          </div>
        </div>
        <hr />
        <div className='flex flex-row  gap-14'>
          <div className='flex-1'>
            <TargetGroupDropdownSelector name='targetGroup' form={form} />
          </div>
          {/* Invisible filler to get column width correct */}
          <div className='invisible flex-1' />
        </div>
      </Form>
    </div>
  );
}
