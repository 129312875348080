import {
  QK,
  useEventsActive,
  useEventsCategories,
  useEventsDraft,
  useEventsHistory,
} from 'ck-queries';

import { formatEventPrefill } from 'ck-utils';
import { useQuery } from '@tanstack/react-query';

export const useEventsPrefill = (
  eventId: string | undefined,
  isCopy: boolean
) => {
  const { data: activeEvents } = useEventsActive();
  const { data: historyEvents } = useEventsHistory();
  const { data: categories } = useEventsCategories();
  const { data: draftEvents } = useEventsDraft();

  const events = [
    ...(activeEvents ?? []),
    ...(historyEvents ?? []),
    ...(draftEvents ?? []),
  ];

  return useQuery({
    queryKey: [...QK.events.prefill, eventId, isCopy],
    queryFn: async () => {
      const maybeEvent = events.find(({ id }) => id === eventId);
      return formatEventPrefill(maybeEvent, isCopy, categories);
    },
    enabled: Boolean(activeEvents) && Boolean(categories),
  });
};
