import { Label } from 'ck-components';
import { createContext } from 'react';
import useFormLabelReducer from './useFormLabelReducer';

interface FormLabelProps {
  label: string;
  name?: string;
  description?: string;
  children?: JSX.Element;
}

interface InputContextProps {
  setMaxInputLength: (maxLength: number) => void;
  updateInputLength: (inputLength: number) => void;
}

export const InputContext = createContext<InputContextProps>({
  setMaxInputLength: () => undefined,
  updateInputLength: () => undefined,
});

/**
 * A wrapper component for the label component with custom styling
 * @param label The label to be displayed
 * @param name The name of the form field that is associated with the label
 * @param children The children rendered under the label (most likely a FormTextInput component)
 * @param description Optional description underneath the label
 * @returns
 */

export const FormLabel = ({
  label,
  name,
  description,
  children,
}: FormLabelProps) => {
  const { state, setMaxInputLength, updateInputLength } = useFormLabelReducer();

  const renderInputLength = () => {
    if (state.hasMaxLength) {
      return (
        <span className='lowercase'>
          {state.currentInputLength}/{state.maxLength} tecken
        </span>
      );
    }
  };
  return (
    <div>
      <label
        htmlFor={name || ''}
        className='flex w-full flex-col content-start items-start'
      >
        <div className='flex w-full'>
          <div className='mb-2 mr-2 flex h-full flex-1 flex-col'>
            <Label>{label}</Label>
            {description && (
              <div className='mt-1 w-full overflow-ellipsis whitespace-nowrap text-sm text-gray-400'>
                {description}
              </div>
            )}
          </div>
          <div className='mb-2 flex items-end justify-end whitespace-nowrap text-left text-base font-light tracking-custom text-gray-400 '>
            {renderInputLength()}
          </div>
        </div>
      </label>
      <InputContext.Provider value={{ setMaxInputLength, updateInputLength }}>
        {children}
      </InputContext.Provider>
    </div>
  );
};
