import { ArchiveTab, PushNotificationsArchiveModalContents } from 'ck-features';
import {
  GK,
  HistoryTableStructure,
  PushNotification,
  PushNotificationFormatted,
  QK,
  ScheduledNotification,
  ScheduledNotificationFormatted,
  ScheduledTableStructure,
  deleteScheduledNotification,
  getNotificationsDraftFormatted,
  getNotificationsHistoryFormatted,
  getNotificationsScheduledFormatted,
} from 'ck-queries';
import {
  InformationModal,
  MainTitle,
  TabContent,
  TabTitle,
} from 'ck-components';
import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import { Tab } from '@headlessui/react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export function PushNotificationsArchivePage() {
  const scheduledTableStructure: ScheduledTableStructure[] = [
    { title: 'TITEL', dataPath: 'titleSwedish' },
    { title: 'SKICKAS', dataPath: 'scheduleTimeFormatted' },
    { title: 'VISAS FÖR', dataPath: 'targetGroupString' },
    { title: 'SKAPAD AV', dataPath: 'createdBy' },
  ];

  const historyTableStructure: HistoryTableStructure[] = [
    { title: 'TITEL', dataPath: 'titleSwedish' },
    { title: 'TYP', dataPath: 'typeString' },
    { title: 'SKICKAD', dataPath: 'date' },
    { title: 'VISAS FÖR', dataPath: 'targetGroupString' },
    { title: 'SKAPAD AV', dataPath: 'createdBy' },
    { title: 'ÖPPNAD', dataPath: 'openingStats' },
  ];
  const draftTableStructure: ScheduledTableStructure[] = [
    { title: 'TITEL', dataPath: 'titleSwedish' },
    { title: 'VISAS FÖR', dataPath: 'targetGroupString' },
    { title: 'SKAPAD AV', dataPath: 'createdBy' },
  ];

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState<
    (PushNotificationFormatted & ScheduledNotificationFormatted) | null
  >(null);
  const [showTableClickModal, setShowTableClickModal] = useState(false);
  const showRowClickModal = (
    data: PushNotificationFormatted & ScheduledNotificationFormatted
  ) => {
    setShowTableClickModal(!showTableClickModal);
    setRowData(data);
  };

  const deleteScheduledNotificationMutationConfig: UseMutationOptions<
    Response,
    unknown,
    string
  > = {
    mutationFn: (notificationId: string) =>
      toast.promise(
        deleteScheduledNotification(notificationId),
        {
          loading: 'Laddar...',
          success: 'Pushnotisen borttagen',
          error: 'Kunde inte ta bort pushnotisen',
        },
        {
          duration: 8000,
        }
      ),
    onSettled: () => {
      queryClient.invalidateQueries(GK.notifications);
    },
  };

  const onPushNotificationCopyClick = async ({ id }: ScheduledNotification) => {
    if (id) {
      navigate(`/push-notifications/copy/${id}`);
    } else {
      toast.error('Kunde inte hitta notisen. Prova uppdatera sidan.');
    }
  };

  const onHistoryCopy = ({ type, id }: PushNotification) => {
    if (type === 'Alarm') {
      if (id) {
        navigate(`/push-notifications/alarm/copy/${id}`);
      } else {
        toast.error('Kunde inte hitta notisen. Prova uppdatera sidan.');
      }
    } else {
      if (id) {
        navigate(`/push-notifications/copy/${id}`);
      } else {
        toast.error('Kunde inte hitta notisen. Prova uppdatera sidan.');
      }
    }
  };

  const onEditClick = async ({ id }: ScheduledNotification) => {
    if (id) {
      navigate(`/push-notifications/edit/${id}`);
    } else {
      toast.error('Notisen kunde inte hittas. Prova uppdatera webbläsaren.');
    }
  };
  return (
    <div className='flex flex-1 flex-col'>
      <InformationModal
        isOpen={showTableClickModal}
        setIsOpen={setShowTableClickModal}
      >
        {rowData && <PushNotificationsArchiveModalContents data={rowData} />}
      </InformationModal>
      <MainTitle label='Arkiv - Pushnotiser' />
      <Tab.Group>
        <Tab.List className='my-2 flex gap-7 border-b-2 border-neutral-200 outline-none'>
          <TabTitle label='SCHEMALAGDA' />
          <TabTitle label='HISTORIK' />
          <TabTitle label='UTKAST' />
        </Tab.List>
        <Tab.Panels>
          {/* SCHEDULED */}
          <TabContent>
            <ArchiveTab
              emptyStateMessage='Det finns inga schemalagda pushnotiser just nu.'
              queryConfig={{
                queryKey: QK.notifications.scheduled,
                queryFn: getNotificationsScheduledFormatted,
              }}
              mutationConfig={deleteScheduledNotificationMutationConfig}
              tableStructure={scheduledTableStructure}
              onRowClick={showRowClickModal}
              onEditClick={onEditClick}
              conditionDisableEditClick={(data: ScheduledNotification) =>
                data.type === 'Alarm'
              }
              conditionDisableCopyClick={(data: ScheduledNotification) =>
                data.type === 'Alarm'
              }
              onCopyClick={onPushNotificationCopyClick}
            />
          </TabContent>
          {/* HISTORY */}
          <TabContent>
            <ArchiveTab
              emptyStateMessage='Det finns inga pushnotiser i historiken.'
              queryConfig={{
                queryKey: QK.notifications.history,
                queryFn: getNotificationsHistoryFormatted,
              }}
              tableStructure={historyTableStructure}
              onRowClick={showRowClickModal}
              onCopyClick={onHistoryCopy}
            />
          </TabContent>
          {/* DRAFT */}
          <TabContent>
            <ArchiveTab
              emptyStateMessage='Det finns inga utkast pushnotiser.'
              queryConfig={{
                queryKey: QK.notifications.draft,
                queryFn: getNotificationsDraftFormatted,
              }}
              mutationConfig={deleteScheduledNotificationMutationConfig}
              tableStructure={draftTableStructure}
              onRowClick={showRowClickModal}
              onCopyClick={onPushNotificationCopyClick}
              onEditClick={onEditClick}
            />
          </TabContent>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
