import {
  PostPayeeReportForm,
  ReportRangeType,
  useReportPaymentReceiverDownload,
} from 'ck-queries';

import { ConfirmButton } from 'ck-components';
import { ReportRange } from '../components/ReportRange';
import { useForm } from 'react-hook-form';
import { usePresetReportRanges } from '../hooks/usePresetReportRanges';

export const EventReportsPayeeTab = () => {
  const ranges = usePresetReportRanges();
  const M_DownloadPaymentReceiverReport = useReportPaymentReceiverDownload();
  const form = useForm<PostPayeeReportForm & { period: ReportRangeType }>({
    defaultValues: {
      period: 'Idag',
      from: ranges.find((r) => r.label === 'Idag')?.range.start,
      to: ranges.find((r) => r.label === 'Idag')?.range.end,
    },
  });

  return (
    <form
      className='w-full'
      onSubmit={form.handleSubmit(
        (data) => {
          M_DownloadPaymentReceiverReport.mutateAsync({
            from: new Date(data.from).toISOString(),
            to: new Date(data.to).toISOString(),
          });
        },
        (error) => {
          console.log('submit invalid', error);
        }
      )}
    >
      <ReportRange
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore asdsa
        form={form}
      />
      <ConfirmButton
        type='submit'
        label={'Hämta rapport'}
        onClick={() => {
          form.trigger();
        }}
      />
    </form>
  );
};
