import { ArchiveTab, ThemeArchiveDetailsModal } from 'ck-features';
import {
  GK,
  QK,
  ThemeTableDataPaths,
  ThemeTableStructure,
  deleteTheme,
  getThemesActive,
  getThemesDraft,
  getThemesHistory,
  getThemesUpcoming,
} from 'ck-queries';
import {
  InformationModal,
  MainTitle,
  TabContent,
  TabTitle,
} from 'ck-components';
import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import { Tab } from '@headlessui/react';
import { toast } from 'react-hot-toast';
import { useState } from 'react';
import { useTypedNavigate } from 'ck-utils';

export function ThemesArchivePage() {
  const tableStructure: ThemeTableStructure[] = [
    { title: 'TEMA', dataPath: 'title' },
    { title: 'START', dataPath: 'startFormatted' },
    { title: 'SLUT', dataPath: 'endFormatted' },
    { title: 'SKAPAD AV', dataPath: 'createdBy' },
  ];
  const queryClient = useQueryClient();
  const [rowData, setRowData] = useState<ThemeTableDataPaths | null>(null);
  const [showTableClickModal, setShowTableClickModal] = useState(false);
  const showRowClickModal = (data: ThemeTableDataPaths) => {
    setShowTableClickModal(!showTableClickModal);
    setRowData(data);
  };
  const { navigate } = useTypedNavigate();

  const deleteThemeMutationConfig: UseMutationOptions<
    Response,
    unknown,
    string
  > = {
    mutationFn: (themeId: string) =>
      toast.promise(deleteTheme(themeId), {
        loading: 'Laddar...',
        success: 'Tema borttaget',
        error: 'Kunde inte ta bort temat',
      }),
    onSettled: () => {
      queryClient.invalidateQueries(GK.themes);
    },
  };
  const onCopyClick = async ({ id }: ThemeTableDataPaths) => {
    if (id) {
      navigate(`/themes/copy/${id}`);
    } else {
      toast.error('Kunde inte hitta temat. Prova uppdatera sidan.');
    }
  };

  const onEditLink = async ({ id }: ThemeTableDataPaths) => {
    if (id) {
      navigate(`/themes/edit/${id}`);
    } else {
      toast.error('Kunde inte hitta temat. Prova uppdatera sidan.');
    }
  };

  return (
    <div className='flex flex-1 flex-col'>
      <InformationModal
        isOpen={showTableClickModal}
        setIsOpen={setShowTableClickModal}
      >
        {rowData && (
          <ThemeArchiveDetailsModal
            themeInfo={rowData}
            themeLinkButtonText={rowData.themeLinkButtonText}
            pngImage={rowData.pngUrl}
            title={rowData.title}
            hasCountdown={Boolean(rowData.countdownStartTime)}
            countdownButtonEnabled={Boolean(
              rowData.countdownButtonEventId || rowData.countdownLinkUrl
            )}
            theme={JSON.parse(rowData.theme)}
          />
        )}
      </InformationModal>
      <MainTitle label='Arkiv - Teman' />
      <Tab.Group defaultIndex={1}>
        <Tab.List className='my-2 flex gap-7 border-b-2 border-neutral-200 outline-none'>
          <TabTitle label='AKTIVA' />
          <TabTitle label='SCHEMALAGDA' />
          <TabTitle label='HISTORIK' />
          <TabTitle label='UTKAST' />
        </Tab.List>
        <Tab.Panels>
          {/* ACTIVE */}
          <TabContent>
            <ArchiveTab
              emptyStateMessage='Det finns inga aktiva teman.'
              queryConfig={{
                queryKey: QK.themes.active,
                queryFn: getThemesActive,
              }}
              mutationConfig={deleteThemeMutationConfig}
              tableStructure={tableStructure}
              onRowClick={showRowClickModal}
              onCopyClick={onCopyClick}
            />
          </TabContent>
          {/* UPCOMING */}
          <TabContent>
            <ArchiveTab
              emptyStateMessage='Det finns inga kommande teman.'
              queryConfig={{
                queryKey: QK.themes.upcoming,
                queryFn: getThemesUpcoming,
              }}
              mutationConfig={deleteThemeMutationConfig}
              tableStructure={tableStructure}
              onRowClick={showRowClickModal}
              onCopyClick={onCopyClick}
              onEditClick={onEditLink}
            />
          </TabContent>
          {/* HISTORY */}
          <TabContent>
            <ArchiveTab
              emptyStateMessage='Det finns inga passerade teman.'
              queryConfig={{
                queryKey: QK.themes.history,
                queryFn: getThemesHistory,
              }}
              mutationConfig={deleteThemeMutationConfig}
              tableStructure={tableStructure}
              onRowClick={showRowClickModal}
              onCopyClick={onCopyClick}
            />
          </TabContent>
          {/* DRAFT */}
          <TabContent>
            <ArchiveTab
              emptyStateMessage='Det finns inga utkast teman.'
              queryConfig={{
                queryKey: QK.themes.draft,
                queryFn: getThemesDraft,
              }}
              mutationConfig={deleteThemeMutationConfig}
              tableStructure={tableStructure}
              onRowClick={showRowClickModal}
              onCopyClick={onCopyClick}
              onEditClick={onEditLink}
            />
          </TabContent>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
