import { CreateThemeForm, defaultPhoneMockConfig } from 'ck-features';
import {
  EditInfo,
  FullScreenLoadingSpinner,
  MainTitle,
  PhoneMockWithToggle,
} from 'ck-components';
import { PhoneMockConfig, ThemeFormData, useThemePrefill } from 'ck-queries';
import { useCallback, useEffect, useState } from 'react';

import { TypedNavigate } from 'ck-utils';
import { useParams } from 'react-router-dom';

interface Props {
  isEdit?: boolean;
  isCopy?: boolean;
}
export function ThemesCreatePage({ isCopy, isEdit }: Props) {
  const { id } = useParams();
  const { data, isLoading } = useThemePrefill(id, Boolean(isCopy));
  const prefill = data?.prefill;
  // State set by the form, used to render the phone preview
  const [config, setConfig] = useState<PhoneMockConfig>(defaultPhoneMockConfig);
  // What view PhoneMockWithToggle component is showing
  const [index, setIndex] = useState<number>(0);

  // Callback to update the state when the form changes
  const onFormChange = useCallback(
    (data: ThemeFormData) => {
      setConfig({
        title: data.title,
        themeLinkButtonText: data.themeLinkButtonText,
        pngImage: data.image || null,
        countdownButtonEnabled: data.countdownButtonType !== 'none',
        hasCountdown: data.countdownEnabled === 'enabled',
        theme: data.theme,
      });
    },
    [setConfig]
  );

  // Can only set a copied theme if it has been fetched
  useEffect(() => {
    if (!isLoading && prefill) {
      setConfig({
        title: prefill.title,
        pngImage: prefill.image,
        theme: prefill.theme,
        hasCountdown: prefill.countdownEnabled === 'enabled',
        themeLinkButtonText: prefill.themeLinkButtonText,
        countdownButtonEnabled: prefill.countdownButtonType !== 'none',
      });
    }
  }, [isLoading, prefill]);

  if (!data && id && !isLoading) {
    // If there is no copy/edit data just go to normal form
    return <TypedNavigate to='/themes/create' replace={true} />;
  }
  if (isLoading) {
    return <FullScreenLoadingSpinner />;
  }

  const mainTitle = isEdit
    ? 'Redigera tema'
    : isCopy && prefill?.title
    ? prefill.title
    : 'Skapa tema';
  return (
    <div className='flex w-full gap-10'>
      <div className='flex flex-col gap-6'>
        {prefill && (
          <EditInfo isDraft={isEdit && Number(prefill.status) === 4} />
        )}
        <MainTitle label={mainTitle} />

        <CreateThemeForm
          handleFormChange={onFormChange}
          prefill={prefill}
          isCopy={isCopy}
          isEdit={isEdit}
        />
      </div>
      <div className='flex w-full max-w-xl justify-center'>
        <PhoneMockWithToggle
          {...config}
          index={index}
          setSelectedIndex={setIndex}
        />
      </div>
    </div>
  );
}
