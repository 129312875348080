import { ThemeDto } from './types';
import dayjs from 'dayjs';
import { standardDateFormat } from 'ck-utils';
import { translateTargetGroup } from 'ck-queries';

/**
 * Formats theme data to display in the table
 * @param themes - Theme data to format
 */
export const formatThemeData = (themes: ThemeDto[]) => {
  // Nullcheck
  if (!themes) {
    return [];
  }

  return themes.map((t) => ({
    ...t,
    startFormatted: dayjs(t.start).format(standardDateFormat),
    endFormatted: dayjs(t.end).format(standardDateFormat),

    targetGroupString: t.targetGroup
      ? `${translateTargetGroup(t.targetGroup.group)} (${
          t.targetGroup.count
        } st)`
      : '',
  }));
};
