import { useGetFunctionTypes } from 'ck-queries';
import { useMemo } from 'react';

export const useFunctionTypes = () => {
  const Q_FUNCTION_TYPES = useGetFunctionTypes();
  return useMemo(
    () => Q_FUNCTION_TYPES.data?.functionTypes ?? [],
    [Q_FUNCTION_TYPES.data]
  );
};
