import { SingleSelectDropdown, SingleSelectDropdownProps } from 'ck-components';
import {
  TargetGroup,
  translateTargetGroup,
  useQueryTargetGroups,
} from 'ck-queries';

import { FieldValues } from 'react-hook-form';
import { useMemo } from 'react';

type Props<T extends FieldValues, K> = Omit<
  SingleSelectDropdownProps<T, K>,
  | 'label'
  | 'defaultValue'
  | 'values'
  | 'labelFromValue'
  | 'onChange'
  | 'controlledConfig'
  | 'onChangeEffect'
  | 'hideValues'
>;

/**
 * DropdownSelector to pick a label-value pair.
 * Needs more work before fully generalized.
 */
export const TargetGroupDropdownSelector = <T extends FieldValues, K>(
  props: Props<T, K>
) => {
  const Q_targetGroups = useQueryTargetGroups();
  const defaultTarget = useMemo(() => {
    if (Q_targetGroups.data) {
      return Q_targetGroups.data[0];
    }
    return undefined;
  }, [Q_targetGroups.data]);

  return (
    <SingleSelectDropdown
      {...props}
      label='VISAS FÖR'
      disabled={
        !Q_targetGroups.data ||
        Q_targetGroups.data.length < 1 ||
        Q_targetGroups.isLoading
      }
      defaultValue={defaultTarget}
      emptyStateLabel={'Välj målgrupp'}
      values={Q_targetGroups.data || ([] as TargetGroup[])}
      labelFromValue={(value: TargetGroup) =>
        `${translateTargetGroup(value.group)} (${value.count} st)`
      }
    />
  );
};
