import { Answers, FormTextInput } from 'ck-components';
import { useEffect, useRef, useState } from 'react';

import { PostPollsRequest } from 'ck-queries';
import { UseFormReturn } from 'react-hook-form';

interface Props {
  parentForm: UseFormReturn<PostPollsRequest>;
}

export type AnswersFormKey = 'answersSwedish' | 'answersEnglish';

// NOTE: This component was built pretty quick n' dirty. Structure-wise it's not the best.
export const AnswersGroup = ({ parentForm }: Props) => {
  const { setValue, setError, clearErrors, watch } = parentForm;

  const answersSwedish = watch('answersSwedish');
  const answersEnglish = watch('answersEnglish');

  // Applies to both Swedish and English answers
  const [showEditable, setShowEditable] = useState(true);

  // Input state propped down to each Answers.tsx
  const [swedishInput, setSwedishInput] = useState('');
  const [englishInput, setEnglishInput] = useState('');

  // Refs used to focus fields
  const swedishInputRef = useRef<HTMLInputElement>(null);
  const englishInputRef = useRef<HTMLInputElement>(null);

  // Commits the input value to the list in the form
  const commitAnswerToList = () => {
    const isSwedishInputEmpty = swedishInput.trim() === '';
    const isEnglishInputEmpty = englishInput.trim() === '';

    // Early return if both inputs are empty
    if (isSwedishInputEmpty && isEnglishInputEmpty) {
      setShowEditable(false);
      return;
    }

    // Show error message if only one input is empty
    if (isSwedishInputEmpty) {
      setError('answersSwedish', {
        type: 'manual',
        message: 'Båda språken måste fyllas i.',
      });
      return;
    }

    if (isEnglishInputEmpty) {
      setError('answersEnglish', {
        type: 'manual',
        message: 'Båda språken måste fyllas i.',
      });
      return;
    }

    // Transfer values from editable input to list in form
    setValue('answersSwedish', [...answersSwedish, swedishInput]);
    setValue('answersEnglish', [...answersEnglish, englishInput]);

    // Reset input fields
    setSwedishInput('');
    setEnglishInput('');

    // Clear error messages
    clearErrors(['answersSwedish', 'answersEnglish']);

    // Hide input fields
    setShowEditable(false);
  };

  const handleDeleteAnswer = (idx: number) => {
    // Show the editable input field if there is only one answer left
    if (answersSwedish?.length === 1 || answersEnglish?.length === 1) {
      setShowEditable(true);
    }

    // Remove the answer from the list by index
    setValue(
      'answersSwedish',
      answersSwedish.filter((_v, i) => i !== idx)
    );
    setValue(
      'answersEnglish',
      answersEnglish.filter((_v, i) => i !== idx)
    );
  };

  // When user presses "+ Lägg till svar"
  const handleAddAnswer = (inputRef: React.RefObject<HTMLInputElement>) => {
    // Make editable input visible
    setShowEditable(true);

    // Focus the editable input field
    // Tiny delay so that input field is in the DOM before focusing
    setTimeout(() => {
      inputRef.current?.focus();
    }, 1);
  };

  // Hide editable input field if both inputs are empty
  useEffect(() => {
    if (
      swedishInput.trim() === '' &&
      englishInput.trim() === '' &&
      answersSwedish?.length > 0
    ) {
      setShowEditable(false);
    }
  }, [answersSwedish?.length, englishInput, swedishInput]);

  return (
    <div className='flex gap-14'>
      <div className='flex-1'>
        <FormTextInput
          label={'FRÅGA'}
          parentForm={parentForm}
          name='questionSwedish'
          maxLength={64}
          description=''
          errorsAlwaysTakeUpSpace={false}
          className='mb-4'
        />
        <Answers
          showEditable={showEditable}
          answersList={answersSwedish}
          inputState={swedishInput}
          setInputState={setSwedishInput}
          parentForm={parentForm}
          formValueKey='answersSwedish'
          commitAnswer={commitAnswerToList}
          deleteAnswer={handleDeleteAnswer}
          inputRef={swedishInputRef}
          addAnswer={handleAddAnswer}
        />
      </div>
      <div className='flex-1'>
        <FormTextInput
          label={'QUESTION'}
          parentForm={parentForm}
          name='questionEnglish'
          maxLength={64}
          errorsAlwaysTakeUpSpace={false}
          className='mb-4'
        />
        <Answers
          showEditable={showEditable}
          answersList={answersEnglish}
          inputState={englishInput}
          setInputState={setEnglishInput}
          parentForm={parentForm}
          formValueKey='answersEnglish'
          commitAnswer={commitAnswerToList}
          deleteAnswer={handleDeleteAnswer}
          inputRef={englishInputRef}
          addAnswer={handleAddAnswer}
        />
      </div>
    </div>
  );
};
