import { ConfirmButton, MultiSelectDropdown } from 'ck-components';
import {
  PostHostReportForm,
  PostPayeeReportForm,
  ReportRangeType,
  useEventsHosts,
  useReportHostDownload,
} from 'ck-queries';
import { UseFormReturn, useForm } from 'react-hook-form';

import { ReportRange } from '../components/ReportRange';
import { usePresetReportRanges } from 'ck-pages';

export const EventReportsOrganizerTab = () => {
  const Q_hosts = useEventsHosts();
  const ranges = usePresetReportRanges();
  const M_DownloadHostsReport = useReportHostDownload();
  const form = useForm<PostHostReportForm & { period: ReportRangeType }>({
    defaultValues: {
      period: 'Idag',
      from: ranges.find((r) => r.label === 'Idag')?.range.start,
      to: ranges.find((r) => r.label === 'Idag')?.range.end,
      committeeIds: [],
    },
  });
  const { register } = form;
  register('committeeIds', { required: 'Välj minst en kommittee' });
  return (
    <form className='w-full'>
      <div className='flex flex-col'>
        <MultiSelectDropdown
          parentForm={form}
          optionsData={Q_hosts.data?.flatMap((h) => h.id) ?? []}
          labelFromOption={(value) =>
            Q_hosts.data?.find((d) => d.id === value)?.name ?? ''
          }
          name={'committeeIds'}
          label='FÖRENING'
        />
        <ReportRange
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore asdsa
          form={form as UseFormReturn<PostPayeeReportForm | PostHostReportForm>}
        />
      </div>
      <ConfirmButton
        label={'Hämta rapport'}
        onClick={async () => {
          console.log(form.getValues());
          form.trigger();
          await form.handleSubmit(
            (data) => {
              M_DownloadHostsReport.mutateAsync({
                from: new Date(data.from).toISOString(),
                committeeIds: data.committeeIds,
                to: new Date(data.to).toISOString(),
              });
            },
            (error) => {
              console.log('submit invalid', error);
            }
          )();
        }}
      />
    </form>
  );
};
