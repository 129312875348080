import { FunctionTypeIdPair } from '../function-types';

export type StatusRaw = 'Active' | 'Expired' | 'Sent' | 'AwaitingResponse';

export interface AdminFromServer {
  inviteId: string;
  email: string;
  expireDate: string;
  status: StatusRaw;
}

export const inviteStatusMap = {
  Active: 'Aktiv',
  Expired: 'Utgått',
  Sent: 'Skickad',
  AwaitingResponse: 'Övergående',
};

// Division from BE - (Sektion)
export interface GetDivisionsResponse extends DivisionBase {
  committees: number;
  admins: number;
}

export interface DivisionFormatted extends DivisionBase {
  committees: string;
  admins: string;
  mustInheritOrgNumber: boolean;
}

interface DivisionBase {
  id: string;
  name: string;
  mustInheritOrgNumber: boolean;
  orgNumber: string;
}

export const roleDisplayNameMap = {
  SuperAdmin: 'Superadmin',
  DivisionAdmin: 'Sektionsnivå',
  CommitteeModerator: 'Kommitténivå',
};

type Guid = string;

export type PostCreateCommittee = {
  divisionId: string;
  name: string;
  email: string;
  orgNumber: string;
  functionTypes: Array<Guid>;
  accountNumber: string | null;
  resultUnit: string | null;
};

export type PostEditCommittee = {
  email: string;
  orgNumber: string;
  functionTypes: Array<Guid>;
  accountNumber: string | null;
  resultUnit: string | null;
};
export interface Admin {
  inviteId: string;
  email: string;
  expireDate: string;
  status: Status;
}

export interface CommitteeFromServer {
  id: string;
  name: string;
  activeEmail: string;
  invitedEmail: string | null;
  status: StatusRaw;
  expireDate: string;
  orgNumber: string;
  functionTypes: FunctionTypeIdPair[];
  accountNumber: string | null;
  resultUnit: string | null;
}

export interface CommitteeFormatted {
  id: string;
  name: string;
  activeEmail: string;
  invitedEmail: string | null;
  status: Status;
  expireDate: string;
  orgNumber: string;
  paidEvents: 'Ja' | 'Nej';
  accountNumber: string | null;
  resultUnit: string | null;
}

export type Status = 'Aktiv' | 'Utgått' | 'Skickad' | 'Övergående';
