import { APIError } from 'ck-global-types';
import { toast } from 'react-hot-toast';

export const errorHandler = (err: unknown) => {
  if (err instanceof APIError) {
    // Always show clientMessage if it exists
    if (err.clientMessageSwedish) {
      toast.error(err.clientMessageSwedish);
    }

    // Show extra toast for every type of error provided
    if (err.errors) {
      Object.entries(err.errors).forEach((e) => toast.error(e[1].join(', ')));
    }

    return;
  }

  // Since we conform all errors to APIError this should never happen
  toast.error(`Något gick fel: ${err}`);
};
