import { PhoneMockConfig, ThemeTableDataPaths } from 'ck-queries';
import { useEffect, useState } from 'react';

import { PhoneMockWithToggle } from 'ck-components';
import { ThemeArchiveDetailsInfo } from './ThemeArchiveDetailsInfo';

interface Props extends PhoneMockConfig {
  themeInfo?: ThemeTableDataPaths;
}

export const ThemeArchiveDetailsModal = (config: Props) => {
  // Co-located state for PhoneMockWithToggle and ThemeArchiveDetailsInfo
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { themeInfo, hasCountdown } = config;

  // If the user has disabled the countdown, we want to show the theme tab
  useEffect(() => {
    if (!hasCountdown) {
      setSelectedIndex(0);
    }
  }, [hasCountdown]);

  return (
    <div className='flex gap-10'>
      <PhoneMockWithToggle
        {...config}
        pngImage={themeInfo?.pngUrl ?? null}
        themeLinkButtonText={themeInfo?.themeLinkButtonText ?? ''}
        index={selectedIndex}
        setSelectedIndex={setSelectedIndex}
      />
      {themeInfo && (
        <ThemeArchiveDetailsInfo data={themeInfo} index={selectedIndex} />
      )}
    </div>
  );
};
