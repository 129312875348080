import { TargetGroup } from 'ck-queries';

// These properties are the same in all poll types
interface PollBase {
  titleEnglish: string;
  titleSwedish: string;
  questionEnglish: string;
  questionSwedish: string;
  targetGroup: TargetGroup;
  isDraft: boolean;
}

export type PollPrefill = (PostPollsRequest & { id: string }) | undefined;

export type PollFromServer = PollBase & {
  id: string;
  answers: Answer[];
  createdBy: string;
  openedByCount: number;
  sent?: string;
  scheduleTime?: string;
  imageUrl?: string;
  shouldSendPushNotification: boolean;
};

export type Poll = PollFromServer & {
  targetGroupString: string;
  openedByString: string;
};

type Answer = {
  answerEnglish: string;
  answerSwedish: string;
  numberOfVotes: number;
  numberOfVotesPercentage: number;
};

export type PostPollsRequest = PollBase & {
  sendPushNotification: 'true' | 'false';
  answersEnglish: string[];
  answersSwedish: string[];
  image: File | null;
  scheduleTime: string | null;
};

type PollsTableStructure = {
  title: 'TITEL' | 'SKICKAS' | 'SKICKADES' | 'MÅLGRUPP' | 'SKAPAD AV';
  dataPath: keyof Poll;
};

export const pollsScheduledTableStructure: PollsTableStructure[] = [
  { title: 'TITEL', dataPath: 'titleSwedish' },
  { title: 'SKICKAS', dataPath: 'scheduleTime' },
  { title: 'MÅLGRUPP', dataPath: 'targetGroupString' },
  { title: 'SKAPAD AV', dataPath: 'createdBy' },
];

export const pollsHistoryTableStructure: PollsTableStructure[] = [
  { title: 'TITEL', dataPath: 'titleSwedish' },
  { title: 'SKICKADES', dataPath: 'sent' },
  { title: 'MÅLGRUPP', dataPath: 'targetGroupString' },
  { title: 'SKAPAD AV', dataPath: 'createdBy' },
];

export const pollsDraftTableStructure: PollsTableStructure[] = [
  { title: 'TITEL', dataPath: 'titleSwedish' },
  { title: 'MÅLGRUPP', dataPath: 'targetGroupString' },
  { title: 'SKAPAD AV', dataPath: 'createdBy' },
];
