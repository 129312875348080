import { EventIdName } from './types';
import { QK } from 'ck-queries';
import { fetchWithErrorThrowing } from 'ck-utils';
import { useQuery } from '@tanstack/react-query';

export const useEventsIdName = () => {
  return useQuery({
    queryKey: QK.events.idName,
    queryFn: getEventsIdName,
  });
};

async function getEventsIdName(): Promise<EventIdName[]> {
  const res = await fetchWithErrorThrowing(`/events`, {
    method: 'GET',
  });

  const data = await res.json();
  return data.events;
}
