import { MainTitle, TabContent, TabList, TabTitle } from 'ck-components';

import { EventReportsOrganizerTab } from './tabs/OrganizerTab';
import { EventReportsPayeeTab } from './tabs/PayeeTab';
import { Tab } from '@headlessui/react';
import { useHasPaymentReceiverReportFunctionType } from './hooks/hasPaymentReceiverReportFunctionType';

export const EventReportsPage = () => {
  const hasPaymentReceiverReportFunctionType =
    useHasPaymentReceiverReportFunctionType();

  return (
    <div className='flex flex-1 flex-col'>
      <MainTitle label={'Rapporter'} customStyle='pb-8' />
      <Tab.Group>
        <TabList>
          <TabTitle label='ARRANGÖR' />
          {hasPaymentReceiverReportFunctionType && (
            <TabTitle label='BETALNINGSMOTTAGARE' />
          )}
        </TabList>
        <div className='mt-4 w-fit rounded-2xl border bg-white p-4'>
          <Tab.Panels>
            {/* ARRANGÖR */}
            <TabContent>
              <EventReportsOrganizerTab />
            </TabContent>
            {/* BETALNINGSMOTTAGARE */}
            {hasPaymentReceiverReportFunctionType && (
              <TabContent>
                <EventReportsPayeeTab />
              </TabContent>
            )}
          </Tab.Panels>
        </div>
      </Tab.Group>
    </div>
  );
};
