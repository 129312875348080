import { DivisionFormatted, GetDivisionsResponse } from './types';

import { QK } from 'ck-queries';
import { fetchWithErrorThrowing } from 'ck-utils';
import { useQuery } from '@tanstack/react-query';

// Query hook
export const useDivisions = () => {
  return useQuery({
    queryKey: QK.divisions,
    queryFn: getDivisions,
  });
};

// Query function
export async function getDivisions(): Promise<DivisionFormatted[]> {
  const res = await fetchWithErrorThrowing(`/divisions`, {
    method: 'GET',
  });

  const data: { divisions: GetDivisionsResponse[] } = await res.json();

  return formatDivisions(data.divisions);
}

// Query formatting
// Formatting to add 'st' to the end of the number of committees and admins
const formatDivisions = (divisions: GetDivisionsResponse[]) =>
  divisions.map(
    (d) =>
      ({
        ...d,
        name: d.name.length <= 4 ? `${d.name}-sektionen` : d.name,
        committees: `${d.committees} st`,
        admins: `${d.admins} st`,
      } as DivisionFormatted)
  );
