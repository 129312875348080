import { useMemo, useState } from 'react';

import { Checkmark } from 'ck-assets';
import { Listbox } from '@headlessui/react';
import { ReactSVG } from 'react-svg';
import { Search } from 'ck-assets';
import { SingleSelectValueLabelPair } from './SingleSelectDropdown';
import clsx from 'clsx';

export interface SingleSelectDropdownListProps<K> {
  selectedOption: K;
  valueLabelPairs: SingleSelectValueLabelPair<K>[];
  withSearch?: boolean;
}
/**
 * Sub-component of DropdownSelector.tsx
 * Separated to make it readable.
 *
 * @returns DropdownList for use in DropdownSelector
 */
export const SingleSelectDropdownList = <K,>({
  selectedOption,
  valueLabelPairs,
  withSearch,
}: SingleSelectDropdownListProps<K>) => {
  const [optionSearch, setOptionSearch] = useState('');

  const filteredValueLabelPairs = useMemo(
    () =>
      optionSearch !== ''
        ? valueLabelPairs.filter((vlp) =>
            vlp.label.toLowerCase().includes(optionSearch.toLowerCase())
          )
        : valueLabelPairs,
    [optionSearch, valueLabelPairs]
  );

  return (
    <Listbox.Options
      className={clsx(
        'absolute z-40 flex w-full  flex-col overflow-y-auto rounded border-2 border-gray-300 bg-white',
        !selectedOption && '-mt-[1px]'
      )}
    >
      <div className='relative max-h-[300px]'>
        {withSearch && (
          <div className='m-3 flex flex-row items-center rounded-full border bg-white p-1'>
            <ReactSVG src={Search} />
            <input
              className=' w-full rounded-full  text-slate-300 focus:outline-none'
              placeholder='Sök'
              value={optionSearch}
              onChange={(e) => {
                setOptionSearch(e.target.value);
              }}
            />
          </div>
        )}
        {filteredValueLabelPairs.map((vlp, idx) => (
          <Listbox.Option
            as='div'
            key={`${vlp.value}-${idx}`}
            value={vlp.value}
            className='flex h-14 w-full cursor-pointer items-center border-b border-gray-300 bg-white px-4'
          >
            <li className='flex w-full items-center justify-between'>
              {vlp.label}
              {vlp.value === selectedOption && <ReactSVG src={Checkmark} />}
            </li>
          </Listbox.Option>
        ))}
      </div>
    </Listbox.Options>
  );
};
