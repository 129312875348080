import { PostAlarm, PushNotification } from 'ck-queries';

import { removeNullUndefinedProperties } from './removeNullUndefinedProperties';

export const formatAlarmPrefill = (
  rawPrefill: PushNotification | undefined,
  isCopy: boolean
) => {
  if (!rawPrefill) return { prefill: undefined };
  const prefill = removeNullUndefinedProperties(rawPrefill);
  const titleEnglish = isCopy
    ? `Copy of ${prefill.titleEnglish}`
    : prefill.titleEnglish;
  const titleSwedish = isCopy
    ? `Kopia av ${prefill.titleSwedish}`
    : prefill.titleSwedish;

  const formattedPrefill: Omit<PostAlarm, 'targetGroup'> = {
    ...prefill,
    titleEnglish: titleEnglish,
    titleSwedish: titleSwedish,
  };
  return { prefill: formattedPrefill };
};
