import { ModalColumn, ModalLabelAndText } from 'ck-components';
import {
  PushNotificationFormatted,
  ScheduledNotificationFormatted,
} from 'ck-queries';

import { testIds } from 'src/testIds';

type Props = {
  data: PushNotificationFormatted & ScheduledNotificationFormatted;
};
export const PushNotificationsArchiveModalContents = ({ data }: Props) => {
  return (
    <div className='flex flex-col'>
      <div className='flex gap-32'>
        <ModalColumn title='Svenska'>
          <ModalLabelAndText label='RUBRIK' text={data.titleSwedish} />
          <ModalLabelAndText
            label='KORT MEDDELANDE'
            text={data.notificationMessageSwedish}
          />
          <ModalLabelAndText
            label='LÅNGT MEDDELANDE'
            text={data.inboxMessageSwedish}
          />
        </ModalColumn>
        <ModalColumn title='English'>
          <ModalLabelAndText label='TITLE' text={data.titleEnglish} />
          <ModalLabelAndText
            label='SHORT MESSAGE'
            text={data.notificationMessageEnglish}
          />
          <ModalLabelAndText
            label='LONG MESSAGE'
            text={data.inboxMessageEnglish}
          />
        </ModalColumn>
      </div>
      <div className='my-10 border-t border-neutral-200' />
      <div className='flex gap-12'>
        <ModalLabelAndText label='VISAS FÖR' text={data.targetGroupString} />
        <ModalLabelAndText label='SKAPAD AV' text={data.createdBy} />
        {data.openingStats && !data.isDraft && (
          <ModalLabelAndText label='ÖPPNAD' text={data.openingStats} />
        )}
        <div
          className='self-center text-base font-thin italic'
          data-testid={testIds.pushNotifications.modal.sentAt}
        >
          {data.scheduleTimeFormatted
            ? `Skickas ${data.scheduleTimeFormatted}`
            : `Skickades ${data.date}`}
        </div>
      </div>
    </div>
  );
};
